import React , { useState }  from "react";
import api from "../../api";
import { Box, Button, TextField , CircularProgress   } from "@mui/material";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const InvestorForm = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    investor_name: "",
    industry: "",
    country: "",
    investor_type: "",
    number_of_portfolio_companies: "",
  };

  const onSubmit = async (values, { setErrors }) => {
    try {
      setIsLoading(true);
      await api.post("/investor/", values);
      console.log(values);
      formik.resetForm();
      toast.success("Investor created successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
    catch (error) {
      if (error.response && error.response.data) {
        // If the API returns detailed error messages, set them as form errors
        setErrors(error.response.data.detail);
        toast.error(error.response.data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        // Generic error handling
        console.error("API Error:", error);
        // You can display a generic error message to the user if needed
        setErrors({ api: "An error occurred while processing your request." });
        toast.error("An error occurred while processing your request.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const validationSchema = yup.object({
    investor_name: yup.string().required("Required"),
    industry: yup.string().required("Required"),
    country: yup.string().required("Required"),
    investor_type: yup.string().required("Required"),
    number_of_portfolio_companies: yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  console.log(formik.errors);

  return (
    <Box m="20px">
      <Header
        title="ADD investor"
        subtitle="lets document your journey to success"
      />
      {isLoading && (
        <Box display="flex" justifyContent="center" mt="20px">
          <CircularProgress />
        </Box>
      )}
      <Formik>
        <form onSubmit={formik.handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="INVESTOR NAME"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.investor_name}
              name="investor_name"
              error={
                !!formik.touched.investor_name && !!formik.errors.investor_name
              }
              helperText={
                formik.touched.investor_name && formik.errors.investor_name
              }
              sx={{ gridColumn: "span 1" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="INDUSTRY"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.industry}
              name="industry"
              error={!!formik.touched.industry && !!formik.errors.industry}
              helperText={formik.touched.industry && formik.errors.industry}
              sx={{ gridColumn: "span 1" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="COUNTRY"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.country}
              name="country"
              error={!!formik.touched.country && !!formik.errors.country}
              helperText={formik.touched.country && formik.errors.country}
              sx={{ gridColumn: "span 1" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="INVESTOR TYPE"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.investor_type}
              name="investor_type"
              error={
                !!formik.touched.investor_type && !!formik.errors.investor_type
              }
              helperText={
                formik.touched.investor_type && formik.errors.investor_type
              }
              sx={{ gridColumn: "span 1" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="number"
              label="NUMBER OF PORTFOLIO COMPANIES"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.number_of_portfolio_companies}
              name="number_of_portfolio_companies"
              error={
                !!formik.touched.number_of_portfolio_companies &&
                !!formik.errors.number_of_portfolio_companies
              }
              helperText={
                formik.touched.number_of_portfolio_companies &&
                formik.errors.number_of_portfolio_companies
              }
              sx={{ gridColumn: "span 1" }}
            />
          </Box>
          <Box display="flex" justifyContent="end" mt="20px">
            <Button type="submit" color="secondary" variant="contained">
              Create New Deal
            </Button>
          </Box>
        </form>
      </Formik>
      <ToastContainer />
    </Box>
  );
};

export default InvestorForm;
