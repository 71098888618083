import { tokens } from "../theme";

export const mockAmountbyYear = [
  {
    id: "Years",
    data: [
      {
        x: "2006",
        y: 6.0,
      },
      {
        x: "2007",
        y: 3.7,
      },
      {
        x: "2008",
        y: 7.0,
      },
      {
        x: "2009",
        y: 7.8,
      },
      {
        x: "2010",
        y: 11.1137,
      },
      {
        x: "2011",
        y: 20.615,
      },
      {
        x: "2012",
        y: 97.212,
      },
      {
        x: "2013",
        y: 200.513,
      },
      {
        x: "2014",
        y: 372.8174,
      },
      {
        x: "2015",
        y: 300.27755,
      },
      {
        x: "2016",
        y: 759.2582,
      },
      {
        x: "2017",
        y: 473.1867,
      },
      {
        x: "2018",
        y: 1068.437345,
      },
      {
        x: "2019",
        y: 1824.31286,
      },
      {
        x: "2020",
        y: 885.551692,
      },
      {
        x: "2021",
        y: 3949.0817,
      },
      {
        x: "2022",
        y: 3766.5388,
      },
    ],
  },
];

export const RegionalStats =[
  {
    "id": "Southern Africa",
    "data": [

      {
        "x": "2016",
        "y": 30
      },
      {
        "x": "2017",
        "y": 35
      },
      {
        "x": "2018",
        "y": 96
      },
      {
        "x": "2019",
        "y": 113
      },
      {
        "x": "2020",
        "y": 87
      },
      {
        "x": "2021",
        "y": 121
      },
      {
        "x": "2022",
        "y": 59
      },

    ]
  },
  {
    "id": "East Africa",
    "data": [
      

      {
        "x": "2016",
        "y": 44
      },
      {
        "x": "2017",
        "y": 57
      },
      {
        "x": "2018",
        "y": 165
      },
      {
        "x": "2019",
        "y": 142
      },
      {
        "x": "2020",
        "y": 120
      },
      {
        "x": "2021",
        "y": 136
      },
      {
        "x": "2022",
        "y": 64
      },

    ]
  },
  {
    "id": "Northern Africa",
    "data": [
      
 
      {
        "x": "2016",
        "y": 16
      },
      {
        "x": "2017",
        "y": 39
      },
      {
        "x": "2018",
        "y": 88
      },
      {
        "x": "2019",
        "y": 107
      },
      {
        "x": "2020",
        "y": 98
      },
      {
        "x": "2021",
        "y": 135
      },
      {
        "x": "2022",
        "y": 96
      },
 
    ]
  },
  {
    "id": "West Africa",
    "data": [
      

      {
        "x": "2016",
        "y": 29
      },
      {
        "x": "2017",
        "y": 41
      },
      {
        "x": "2018",
        "y": 168
      },
      {
        "x": "2019",
        "y": 142
      },
      {
        "x": "2020",
        "y": 154
      },
      {
        "x": "2021",
        "y": 209
      },
      {
        "x": "2022",
        "y": 145
      },

    ]
  },
  {
    "id": "Central Africa",
    "data": [
 
      {
        "x": "2016",
        "y": 2
      },
      {
        "x": "2017",
        "y": 4
      },
      {
        "x": "2018",
        "y": 10
      },
      {
        "x": "2019",
        "y": 6
      },
      {
        "x": "2020",
        "y": 6
      },
      {
        "x": "2021",
        "y": 10
      },
      {
        "x": "2022",
        "y": 3
      }
    ]
  }
];

export const AmountInvestors = [
    {
        "id": "Cdc Group",
        "label": "Cdc Group",
        "value": 1301.66576
    },
    
    {
        "id": "Rocket Internet",
        "label": "Rocket Internet",
        "value": 755.0
    },
    {
      "id": "Tiger Global Management",
      "label": "Tiger Global Management",
      "value": 792.5
  },
    {
        "id": "Mtn Group",
        "label": "Mtn Group",
        "value": 740.0
    },

    {
        "id": "Goldman Sachs",
        "label": "Goldman Sachs",
        "value": 700.75
    },
    {
        "id": "Endeavor Catalyst",
        "label": "Endeavor Catalyst",
        "value": 624.0
    },
];



export const DealsPieData = [
  { id: "2014", label: "2014", value: 37, color: "hsl(251, 58%, 47%)" },
  { id: "2017", label: "2017", value: 197, color: "hsl(187, 77%, 54%)" },
  { id: "2022", label: "2022", value: 479, color: "hsl(282, 70%, 55%)" },
  { id: "2020", label: "2020", value: 495, color: "hsl(50, 98%, 58%)" },
  { id: "2019", label: "2019", value: 628, color: "hsl(333, 84%, 49%)" },
  { id: "2021", label: "2021", value: 707, color: "hsl(93, 62%, 45%)" },
  { id: "2018", label: "2018", value: 557, color: "hsl(320, 80%, 48%)" },
  { id: "2016", label: "2016", value: 135, color: "hsl(43, 60%, 58%)" },
  { id: "2015", label: "2015", value: 75, color: "hsl(41, 81%, 40%)" },
  { id: "2012", label: "2012", value: 20, color: "hsl(310, 53%, 45%)" },
  { id: "2013", label: "2013", value: 24, color: "hsl(163, 64%, 54%)" },
  { id: "2010", label: "2010", value: 10, color: "hsl(277, 98%, 51%)" },
  { id: "Others", label: "Others", value: 15, color: "hsl(277, 98%, 51%)" },
];

export const mockGeographyData = [
  {
    id: "ZAF",
    value: 491,
  },
  {
    id: "EGY",
    value: 488,
  },
  {
    id: "KEN",
    value: 520,
  },
  {
    id: "NGA",
    value: 680,
  },
  {
    id: "MWI",
    value: 5,
  },
  {
    id: "GHA",
    value: 143,
  },
  {
    id: "ZWE",
    value: 15,
  },
  {
    id: "ETH",
    value: 34,
  },
  {
    id: "ZMB",
    value: 32,
  },
  {
    id: "RWA",
    value: 53,
  },
  {
    id: "TZA",
    value: 30,
  },
  {
    id: "SEN",
    value: 26,
  },
  {
    id: "MLI",
    value: 11,
  },
  {
    id: "UGA",
    value: 160,
  },
  {
    id: "FRA",
    value: 2,
  },
  {
    id: "CMR",
    value: 27,
  },
  {
    id: "MAR",
    value: 46,
  },
  {
    id: "TUN",
    value: 59,
  },
  {
    id: "TCD",
    value: 1,
  },
  {
    id: "BWA",
    value: 8,
  },
  {
    id: "SDN",
    value: 3,
  },
  {
    id: "GIN",
    value: 1,
  },
  {
    id: "COD",
    value: 8,
  },
  {
    id: "CIV",
    value: 37,
  },
  {
    id: "TGO",
    value: 10,
  },
  {
    id: "MDG",
    value: 3,
  },
  {
    id: "MOZ",
    value: 3,
  },
  {
    id: "AGO",
    value: 7,
  },
  {
    id: "SLE",
    value: 6,
  },
  {
    id: "GBR",
    value: 1,
  },

  {
    id: "MUS",
    value: 17,
  },
  {
    id: "BEN",
    value: 14,
  },
  {
    id: "BFA",
    value: 1,
  },
  {
    id: "NAM",
    value: 4,
  },
  {
    id: "COG",
    value: 2,
  },
  {
    id: "LBR",
    value: 4,
  },
  {
    id: "SSD",
    value: 1,
  },
  {
    id: "DZA",
    value: 6,
  },
  {
    id: "LSO",
    value: 1,
  },
];

export const CompanyCountry = [
  { id: "ZAF", value: 491 },
  { id: "EGY", value: 488 },
  { id: "KEN", value: 520 },
  { id: "NGA", value: 680 },
  { id: "MWI", value: 5 },
  { id: "GHA", value: 143 },
  { id: "ZWE", value: 15 },
  { id: "ETH", value: 34 },
  { id: "ZMB", value: 32 },
  { id: "RWA", value: 53 },
  { id: "TZA", value: 30 },
  { id: "SEN", value: 26 },
  { id: "MLI", value: 11 },
  { id: "UGA", value: 160 },
  { id: "FRA", value: 2 },
  { id: "CMR", value: 27 },
  { id: "MAR", value: 46 },
  { id: "TUN", value: 59 },
  { id: "TCD", value: 1 },
  { id: "BWA", value: 8 },
  { id: "SDN", value: 3 },
  { id: "GIN", value: 1 },
  { id: "COD", value: 8 },
  { id: "CIV", value: 37 },
  { id: "TGO", value: 10 },
  { id: "MDG", value: 3 },
  { id: "MOZ", value: 3 },
  { id: "AGO", value: 7 },
  { id: "SLE", value: 6 },
  { id: "GBR", value: 1 },
  { id: "MUS", value: 17 },
  { id: "BEN", value: 14 },
  { id: "NAM", value: 4 },
  { id: "COG", value: 2 },
  { id: "LBR", value: 4 },
  { id: "SSD", value: 1 },
  { id: "DZA", value: 6 },
  { id: "LSO", value: 1 },
];

export const MapAmount = [
  { id: "ZAF", value: 2086.0 },
  { id: "EGY", value: 1189.4 },
  { id: "KEN", value: 2556.3 },
  { id: "NGA", value: 4319.9 },
  { id: "MWI", value: 27.4 },
  { id: "GHA", value: 702.7 },
  { id: "ZWE", value: 37.0 },
  { id: "ETH", value: 35.3 },
  { id: "ZMB", value: 76.1 },
  { id: "RWA", value: 32.4 },
  { id: "TZA", value: 326.1 },
  { id: "SEN", value: 318.5 },
  { id: "MLI", value: 17.3 },
  { id: "UGA", value: 156.9 },
  { id: "CMR", value: 20.3 },
  { id: "MAR", value: 43.5 },
  { id: "TUN", value: 164.1 },
  { id: "TCD", value: 200.0 },
  { id: "BWA", value: 17.4 },
  { id: "SDN", value: 12.0 },
  { id: "COD", value: 31.3 },
  { id: "CIV", value: 206.8 },
  { id: "TGO", value: 132.1 },
  { id: "MOZ", value: 1.6 },
  { id: "AGO", value: 0.7 },
  { id: "SLE", value: 11.0 },
  { id: "MUS", value: 96.1 },
  { id: "BEN", value: 7.4 },
  { id: "NAM", value: 18.4 },
  { id: "COG", value: 37.5 },
  { id: "LBR", value: 0.2 },
  { id: "DZA", value: 199.1 },
];

export const mockCompanyRaise = [
  {
    "id": "UGA",
    "data": [
      {
        "x": "2017",
        "y": 10
      },
      {
        "x": "2018",
        "y": 39
      },
      {
        "x": "2019",
        "y": 43
      },
      {
        "x": "2020",
        "y": 27
      },
      {
        "x": "2021",
        "y": 22
      },
      {
        "x": "2022",
        "y": 8
      },
    ]
  },

  {
    "id": "ZAF",
    "data": [
      {
        "x": "2017",
        "y": 31
      },
      {
        "x": "2018",
        "y": 77
      },
      {
        "x": "2019",
        "y": 96
      },
      {
        "x": "2020",
        "y": 74
      },
      {
        "x": "2021",
        "y": 102
      },
      {
        "x": "2022",
        "y": 51
      }
    ]
  },
  {
    "id": "EGY",
    "data": [
      {
        "x": "2017",
        "y": 34
      },
      {
        "x": "2018",
        "y": 73
      },
      {
        "x": "2019",
        "y": 95
      },
      {
        "x": "2020",
        "y": 70
      },
      {
        "x": "2021",
        "y": 103
      },
      {
        "x": "2022",
        "y": 75
      }
    ]
  },
  {
    "id": "KEN",
    "data": [
      {
        "x": "2017",
        "y": 39
      },
      {
        "x": "2018",
        "y": 90
      },
      {
        "x": "2019",
        "y": 86
      },
      {
        "x": "2020",
        "y": 74
      },
      {
        "x": "2021",
        "y": 89
      },

      {
        "x": "2022",
        "y": 53
      }
    ]
  },

  {
    "id": "NGA",
    "data": [
      {
        "x": "2017",
        "y": 34
      },
      {
        "x": "2018",
        "y": 123
      },
      {
        "x": "2019",
        "y": 99
      },
      {
        "x": "2020",
        "y": 119
      },
      {
        "x": "2021",
        "y": 148
      },
      {
        "x": "2022",
        "y": 108
      }
    ]
  },
]


export const mockTransactions = [
  {
      "txId": "Cdc Group",
      "user": "M-Kopa",
      "date": "5",
      "cost": "183600000"
  },
  {
      "txId": "International Finance Corporation",
      "user": "Twiga Foods",
      "date": "5",
      "cost": "120750000"
  },
  {
      "txId": "Deciens Capital",
      "user": "Chipper Cash",
      "date": "5",
      "cost": "272200000"
  },
  {
      "txId": "Green Visor Capital",
      "user": "Flutterwave",
      "date": "4",
      "cost": "215230000"
  },
  {
      "txId": "Silicon Badia",
      "user": "Vezeeta",
      "date": "4",
      "cost": "22500000"
  },
  {
      "txId": "Beco Capital",
      "user": "Maxab",
      "date": "4",
      "cost": "101200000"
  },
  {
      "txId": "4Dx Ventures",
      "user": "Maxab",
      "date": "4",
      "cost": "101200000"
  },
  {
      "txId": "Rocket Internet",
      "user": "Jumia Group",
      "date": "4",
      "cost": "745000000"
  },
  {
      "txId": "Chan Zuckerberg Initiative",
      "user": "Andela",
      "date": "4",
      "cost": "364000000"
  },
  {
      "txId": "Spark Capital",
      "user": "Andela",
      "date": "4",
      "cost": "338000000"
  },
  {
      "txId": "Gv",
      "user": "Andela",
      "date": "4",
      "cost": "178000000"
  },
  {
      "txId": "Tlcom Capital Partners",
      "user": "Twiga Foods",
      "date": "4",
      "cost": "90750000"
  },
  {
      "txId": "Cre Venture Capital",
      "user": "Andela",
      "date": "4",
      "cost": "157000000"
  },
  {
      "txId": "Vulcan Capital",
      "user": "Zola Electric",
      "date": "4",
      "cost": "93000000"
  },
  {
      "txId": "Goodwell Investments",
      "user": "Whereismytransport",
      "date": "4",
      "cost": "12370000"
  },
  {
      "txId": "Id4 Ventures",
      "user": "Anka",
      "date": "4",
      "cost": "1519000"
  },
  {
      "txId": "Yellowwoods",
      "user": "Naked Insurance",
      "date": "4",
      "cost": "31600000"
  },
  {
    "txId": "Bittrex",
    "user": "Valr",
    "date": "3",
    "cost": "54900000"
},
];


export const mockComparisonDealsYears = [
  {
    id: "2020",
    data: [
      { x: "Jan", y: 15 },
      { x: "Feb", y: 35 },
      { x: "Mar", y: 33 },
      { x: "Apr", y: 18 },
      { x: "May", y: 41 },
      { x: "Jun", y: 23 },
      { x: "Jul", y: 13 },
      { x: "Aug", y: 36 },
      { x: "Sep", y: 57 },
      { x: "Oct", y: 19 },
      { x: "Nov", y: 34 },
      { x: "Dec", y: 24 },
    ],
  },
  {
    id: "2021",
    data: [
      { x: "Jan", y: 18 },
      { x: "Feb", y: 40 },
      { x: "Mar", y: 37 },
      { x: "Apr", y: 36 },
      { x: "May", y: 45 },
      { x: "Jun", y: 33 },
      { x: "Jul", y: 48 },
      { x: "Aug", y: 27 },
      { x: "Sep", y: 41 },
      { x: "Oct", y: 45 },
      { x: "Nov", y: 60 },
      { x: "Dec", y: 33 },
    ],
  },
  {
    id: "2022",
    data: [
      { x: "Jan", y: 37 },
      { x: "Feb", y: 41 },
      { x: "Mar", y: 62 },
      { x: "Apr", y: 32 },
      { x: "May", y: 37 },
      { x: "Jun", y: 38 },
      { x: "Jul", y: 26 },
      { x: "Aug", y: 2 },
      {x: "Sep", y:0},
      { x: "Oct", y: 11 },
      { x: "Nov", y: 7 },
      { x: "Dec", y: 2 },
    ],
  },
];

export const mockValueDealsCountry =[
  { country: "UGA", amount: 156.9 },
  { country: "TUN", amount: 164.1 },
  { country: "DZA", amount: 199.1 },
  { country: "TCD", amount: 200.0 },
  { country: "CIV", amount: 206.8 },
  { country: "SEN", amount: 318.5 },
  { country: "TZA", amount: 326.1 },
  { country: "GHA", amount: 702.7 },
  { country: "EGY", amount: 1189.4 },
  { country: "ZAF", amount: 2086.0 },
  { country: "KEN", amount: 2556.3 },
  { country: "NGA", amount: 4319.9 }
];

export const mockDataTeam = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    access: "admin",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    age: 42,
    phone: "(421)314-2288",
    access: "manager",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    age: 45,
    phone: "(422)982-6739",
    access: "user",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    age: 16,
    phone: "(921)425-6742",
    access: "admin",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    age: 31,
    phone: "(421)445-1189",
    access: "user",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    age: 150,
    phone: "(232)545-6483",
    access: "manager",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    age: 44,
    phone: "(543)124-0123",
    access: "user",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    age: 36,
    phone: "(222)444-5555",
    access: "user",
  },
  {
    id: 9,
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    age: 65,
    phone: "(444)555-6239",
    access: "admin",
  },
];

export const mockDataContacts = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    address: "0912 Won Street, Alabama, SY 10001",
    city: "New York",
    zipCode: "10001",
    registrarId: 123512,
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    age: 42,
    phone: "(421)314-2288",
    address: "1234 Main Street, New York, NY 10001",
    city: "New York",
    zipCode: "13151",
    registrarId: 123512,
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    age: 45,
    phone: "(422)982-6739",
    address: "3333 Want Blvd, Estanza, NAY 42125",
    city: "New York",
    zipCode: "87281",
    registrarId: 4132513,
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    age: 16,
    phone: "(921)425-6742",
    address: "1514 Main Street, New York, NY 22298",
    city: "New York",
    zipCode: "15551",
    registrarId: 123512,
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    age: 31,
    phone: "(421)445-1189",
    address: "11122 Welping Ave, Tenting, CD 21321",
    city: "Tenting",
    zipCode: "14215",
    registrarId: 123512,
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    age: 150,
    phone: "(232)545-6483",
    address: "1234 Canvile Street, Esvazark, NY 10001",
    city: "Esvazark",
    zipCode: "10001",
    registrarId: 123512,
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    age: 44,
    phone: "(543)124-0123",
    address: "22215 Super Street, Everting, ZO 515234",
    city: "Evertin",
    zipCode: "51523",
    registrarId: 123512,
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    age: 36,
    phone: "(222)444-5555",
    address: "4123 Ever Blvd, Wentington, AD 142213",
    city: "Esteras",
    zipCode: "44215",
    registrarId: 512315,
  },
  {
    id: 9,
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    age: 65,
    phone: "(444)555-6239",
    address: "51234 Avery Street, Cantory, ND 212412",
    city: "Colunza",
    zipCode: "111234",
    registrarId: 928397,
  },
  {
    id: 10,
    name: "Enteri Redack",
    email: "enteriredack@gmail.com",
    age: 42,
    phone: "(222)444-5555",
    address: "4123 Easer Blvd, Wentington, AD 142213",
    city: "Esteras",
    zipCode: "44215",
    registrarId: 533215,
  },
  {
    id: 11,
    name: "Steve Goodman",
    email: "stevegoodmane@gmail.com",
    age: 11,
    phone: "(444)555-6239",
    address: "51234 Fiveton Street, CunFory, ND 212412",
    city: "Colunza",
    zipCode: "1234",
    registrarId: 92197,
  },
];

export const mockDataInvoices = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    cost: "21.24",
    phone: "(665)121-5454",
    date: "03/12/2022",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    cost: "1.24",
    phone: "(421)314-2288",
    date: "06/15/2021",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    cost: "11.24",
    phone: "(422)982-6739",
    date: "05/02/2022",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    cost: "80.55",
    phone: "(921)425-6742",
    date: "03/21/2022",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    cost: "1.24",
    phone: "(421)445-1189",
    date: "01/12/2021",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    cost: "63.12",
    phone: "(232)545-6483",
    date: "11/02/2022",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    cost: "52.42",
    phone: "(543)124-0123",
    date: "02/11/2022",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    cost: "21.24",
    phone: "(222)444-5555",
    date: "05/02/2021",
  },
];

export const mockLineData = [
  {
    id: "japan",
    color: tokens("dark").greenAccent[500],
    data: [
      {
        x: "plane",
        y: 101,
      },
      {
        x: "helicopter",
        y: 75,
      },
      {
        x: "boat",
        y: 36,
      },
      {
        x: "train",
        y: 216,
      },
      {
        x: "subway",
        y: 35,
      },
      {
        x: "bus",
        y: 236,
      },
      {
        x: "car",
        y: 88,
      },
      {
        x: "moto",
        y: 232,
      },
      {
        x: "bicycle",
        y: 281,
      },
      {
        x: "horse",
        y: 1,
      },
      {
        x: "skateboard",
        y: 35,
      },
      {
        x: "others",
        y: 14,
      },
    ],
  },
  {
    id: "france",
    color: tokens("dark").blueAccent[300],
    data: [
      {
        x: "plane",
        y: 212,
      },
      {
        x: "helicopter",
        y: 190,
      },
      {
        x: "boat",
        y: 270,
      },
      {
        x: "train",
        y: 9,
      },
      {
        x: "subway",
        y: 75,
      },
      {
        x: "bus",
        y: 175,
      },
      {
        x: "car",
        y: 33,
      },
      {
        x: "moto",
        y: 189,
      },
      {
        x: "bicycle",
        y: 97,
      },
      {
        x: "horse",
        y: 87,
      },
      {
        x: "skateboard",
        y: 299,
      },
      {
        x: "others",
        y: 251,
      },
    ],
  },
  {
    id: "us",
    color: tokens("dark").redAccent[200],
    data: [
      {
        x: "plane",
        y: 191,
      },
      {
        x: "helicopter",
        y: 136,
      },
      {
        x: "boat",
        y: 91,
      },
      {
        x: "train",
        y: 190,
      },
      {
        x: "subway",
        y: 211,
      },
      {
        x: "bus",
        y: 152,
      },
      {
        x: "car",
        y: 189,
      },
      {
        x: "moto",
        y: 152,
      },
      {
        x: "bicycle",
        y: 8,
      },
      {
        x: "horse",
        y: 197,
      },
      {
        x: "skateboard",
        y: 107,
      },
      {
        x: "others",
        y: 170,
      },
    ],
  },
];

export const yVposts = [
  {
    id: "deals",
    color: tokens("dark").greenAccent[500],
    data: [
      {
        x: 2018.0,
        y: 510,
      },
      {
        x: 2019.0,
        y: 361,
      },
      {
        x: 2017.0,
        y: 153,
      },
      {
        x: 2016.0,
        y: 108,
      },
      {
        x: 2015.0,
        y: 60,
      },
      { x: 2014.0, y: 31 },
      {
        x: 2013.0,
        y: 20,
      },
      {
        x: 2012.0,
        y: 16,
      },
      {
        x: 2011.0,
        y: 6,
      },
      {
        x: 2010.0,
        y: 4,
      },
      {
        x: 2008.0,
        y: 2,
      },
      {
        x: 2009.0,
        y: 2,
      },
      {
        x: 2007.0,
        y: 1,
      },
    ],
  },
];

export const valueVcountry = [
  {
    country: "unK",
    y: 16.1,
  },
  {
    country: "Alg",
    y: 1.7,
  },
  {
    country: "Ang",
    y: 0.7,
  },
  {
    country: "Aus",
    y: 3.4,
  },
  {
    country: "Ben",
    y: 0,
  },
  {
    country: "Bot",
    y: 0.1,
  },
  {
    country: "Cam",
    y: 0.6,
  },
  {
    country: "Egy",
    y: 156.0,
  },
  {
    country: "Eth",
    y: 13.5,
  },
  {
    country: "Fra",
    y: 0.1,
  },
  {
    country: "Gha",
    y: 78.7,
  },
  {
    country: "Ivo",
    y: 3.1,
  },
  {
    country: "Ken",
    y: 809.3,
  },
  {
    country: "Les",
    y: 0,
  },
  {
    country: "Mad",
    y: 0,
  },
  {
    country: "Mal",
    y: 0.1,
  },
  {
    country: "Mal",
    y: 0.7,
  },
  {
    country: "Mau",
    y: 34.0,
  },
  {
    country: "Mor",
    y: 5.3,
  },
  {
    country: "Moz",
    y: 0,
  },
  {
    country: "Nam",
    y: 0.1,
  },
  {
    country: "Nig",
    y: 1386.1,
  },
  {
    country: "Rwa",
    y: 15.2,
  },
  {
    country: "Sen",
    y: 5.8,
  },
  {
    country: "Sie",
    y: 0.0,
  },
  {
    country: "SA",
    y: 623.2,
  },
  {
    country: "Tz",
    y: 169.4,
  },
  {
    country: "Tun",
    y: 8.6,
  },
  {
    country: "Uga",
    y: 37.0,
  },
  {
    country: "UK",
    y: 382.4,
  },
  {
    country: "US",
    y: 204.3,
  },
  {
    country: "USA",
    y: 0.7,
  },
  {
    country: "Zam",
    y: 23.5,
  },
  {
    country: "Zim",
    y: 36.6,
  },
];
