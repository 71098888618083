import React, { useState, useEffect } from "react";
import api from "../api";
import { Box, Typography, useTheme,List, ListItem, ListItemText, Chip } from "@mui/material";
import { tokens } from "../theme";
import { formatDistanceToNow } from 'date-fns';
import { ClipLoader } from "react-spinners";

const RecentDeals = () => {
  const [recentDeals, setRecentDeals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    const fetchRecentDeals = async () => {
      try {
        const response = await api.get("/recent-deals/");
        setRecentDeals(response.data);
      } catch (error) {
        console.error("Error fetching recent deals:", error);
        setError("Error fetching recent deals. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchRecentDeals();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="200px">
        <ClipLoader color="#7F1792" />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" align="center" py={2}>
        {error}
      </Typography>
    );
  }

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper',  maxHeight: 400 }}>
      {recentDeals.map((deal, index) => (
        <ListItem
          key={`${deal.company_mentioned}-${index}`}
          alignItems="flex-start"
          divider={index !== recentDeals.length - 1}
          sx={{
            '&:hover': {
              backgroundColor: colors.primary[400],
            },
          }}
        >
          <ListItemText
            primary={
              <Typography
                variant="subtitle1"
                color={colors.grey[100]}
                fontWeight="bold"
              >
                {deal.company_mentioned}
              </Typography>
            }
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  color={colors.grey[300]}
                >
                  {deal.country_of_the_company} • 
                  {formatDistanceToNow(new Date(deal.date_of_deal_announcement), { addSuffix: true })}
                </Typography>
                <Chip
                  label={deal.usd_amount === 0 ? "Undisclosed" : `$${Number(deal.usd_amount).toLocaleString()}`}
                  color="primary"
                  size="small"
                  sx={{ ml: 1, fontWeight: 'bold' }}
                />
              </React.Fragment>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

export default RecentDeals;