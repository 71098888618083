import React from 'react';
import { Box, Button, Typography, useTheme, useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import ComBarChart from "../../components/ComBarChart";
import { CompanyTransactions } from "../../data/companyData";
import Header from "../../components/Header";
import ComBarRChart from "../../components/ComBarRoundChart";
import CompanyCountry from "../../components/CompanyCountry";
import DealFlow from "../../components/DealFlow";

const Company = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const getGridSpan = (defaultSpan) => {
    if (isSmallScreen) return 12;
    if (isMediumScreen) return Math.min(defaultSpan * 2, 12);
    return defaultSpan;
  };

  const routeChange = () => {
    navigate('/company-form');
  };

  return (
    <Box m="20px">
      <Box display="flex" flexDirection={isSmallScreen ? "column" : "row"} justifyContent="space-between" alignItems={isSmallScreen ? "flex-start" : "center"} mb={3}>
        <Header title="COMPANY" subtitle="Know your company" />
        <Button
        variant="contained"
        disabled
          color="secondary"
          sx={{
            mt: isSmallScreen ? 2 : 0,
            color: colors.white,
            fontSize: "12px",
            fontWeight: "semibold",
            borderRadius: "10px",
            padding: "10px 20px",
            background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(127,23,146,1) 35%, rgba(0,212,255,1) 100%)',
          }}
          onClick={routeChange}
        >
          ADD A NEW COMPANY
        </Button>
      </Box>

      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gap="20px"
      >
        <Box
          gridColumn={`span ${getGridSpan(8)}`}
          backgroundColor={colors.white}
          borderRadius={5}
          p={3}
          height="auto"
          maxHeight={400}
        >
          <Typography variant="h5" fontWeight="600" color={colors.grey[500]} mb={1}>
            Deal Flow
          </Typography>
          <Typography variant="subtitle1" color={colors.grey[300]} mb={2}>
            Deal flow for companies from 2017 - 2022
          </Typography>
          <Box height={300}>
            <DealFlow isDashboard={true} />
          </Box>
        </Box>

        <Box
          gridColumn={`span ${getGridSpan(4)}`}
          backgroundColor={colors.white}
          overflow="auto"
          borderRadius={3}
          height="auto"
          maxHeight={400}
        >
          <Typography variant="h6" p={2} borderBottom={`2px solid ${colors.primary[100]}`}>
            Top 20 Companies with most deals and their country of origin
          </Typography>
          <Box height={340} >
            {CompanyTransactions.map((transaction, i) => (
              <Box
                key={`${transaction.txId}-${i}`}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={`1px solid ${colors.primary[100]}`}
                p={2}
              >
                <Box>
                  <Typography variant="subtitle1" fontWeight="600">
                    {transaction.txId}
                  </Typography>
                  <Typography variant="body2">{transaction.user}</Typography>
                </Box>
                <Typography variant="body2">{transaction.date}</Typography>
                <Box
                  backgroundColor={colors.parent_palatte[100]}
                  p="5px 10px"
                  borderRadius="4px"
                >
                  ${transaction.cost}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        <Box
          gridColumn="span 12"
          gridRow="span auto"
          backgroundColor={colors.white}
          borderRadius={5}
          p={3}
        >
          <Typography variant="h5" fontWeight="600" mb={1}>
            Count of companies by country
          </Typography>
          <Typography variant="subtitle1" mb={2}>
            Countries with most companies
          </Typography>
          <Box height={250}>
            <ComBarChart />
          </Box>
        </Box>

        <Box
          gridColumn="span 12"
          gridRow="span auto"
          backgroundColor={colors.white}
          borderRadius={5}
          p={3}
        >
          <Typography variant="h5" fontWeight="600" mb={1}>
            Count of companies by funding round
          </Typography>
          <Typography variant="subtitle1" mb={2}>
            Funding rounds with most companies
          </Typography>
          <Box height={250}>
            <ComBarRChart />
          </Box>
        </Box>

        <Box
          gridColumn="span 12"
          gridRow="span auto"
          backgroundColor={colors.white}
          borderRadius={5}
          p={3}
        >
          <Typography variant="h5" fontWeight="600" mb={2}>
            Distribution of companies by country
          </Typography>
          <Box height={250}>
            <CompanyCountry isDashboard={false} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Company;