import React, { useEffect, useState } from "react";
import { useTheme, Box, Button } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../theme";
import api from "../api";
import {ClipLoader} from "react-spinners"

const QBarChart = ({ isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [layout, setLayout] = useState("vertical");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/quarterly-investment-past-5-years/');
        setData(response.data);
      } catch (error) {
        console.error("Error fetching the data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const MyResponsiveBar = ({ data, layout }) => (
    <ResponsiveBar
      data={data}
      theme={{
        axis: {
          legend: {
            text: {
              fill: colors.grey[300],
              fontWeight: "bold",
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[500],
              "&:hover": { color: colors.parent_palatte[400] },
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
        tooltip: {
          container: {
            color: colors.primary[500],
          },
        },
      }}
      keys={["Q1", "Q2", "Q3", "Q4"]}
      indexBy="when"
      margin={{ top: 10, right: 130, bottom: 40, left: 90 }}
      padding={0.3}
      innerPadding={0}
      valueFormat=">-$0,"
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "nivo" }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Year",
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Value of deals($M)",
        legendPosition: "middle",
        legendOffset: -60,
      }}
      enableTotals={true}
      totalsOffset={15}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
          from: 'color',
          modifiers: [
              [
                  'darker',
                  '3'
              ]
          ]
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      motionConfig="gentle"
      layout={layout}
      animate={true}
      role="application"
      ariaLabel="Quarterly investment bar chart"
    />
  );

  return (
    <Box 
      position="relative" 
      display="flex" 
      flexDirection="column" 
      justifyContent="center" 
      alignItems="center" 
      height="100%" 
      width="100%"
      minHeight="300px"
    >
      {loading ? (
        <ClipLoader color="#7F1792" />
      ) : (
        <>
          <Button 
            size="small" 
            variant="contained" 
            onClick={() => setLayout(layout === "vertical" ? "horizontal" : "vertical")}
            sx={{ marginBottom: 2 }}
          >
            Switch Layout
          </Button>
          <Box width="100%" height="calc(100% - 40px)" minHeight="260px">
            <MyResponsiveBar data={data} layout={layout} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default QBarChart;