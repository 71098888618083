import React from 'react';
import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../theme";
import { Investments as data } from "../data/companyData";
import { useMediaQuery } from '@mui/material';

const Investments = ({ isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const getMargin = () => {
    if (isSmallScreen) {
      return { top: 0, right: 10, bottom: 50, left: 50 };
    } else if (isMediumScreen) {
      return { top: 5, right: 80, bottom: 50, left: 100 };
    } else {
      return { top: 10, right: 130, bottom: 50, left: 170 };
    }
  };

  const getLegendConfig = () => {
    if (isSmallScreen) {
      return [];
    } else {
      return [
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: isMediumScreen ? 80 : 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ];
    }
  };

  return (
    <div style={{ height: isDashboard ? '300px' : '100%', minHeight: '300px' }}>
      <ResponsiveBar
        data={data}
        theme={{
          axis: {
            legend: {
              text: {
                fill: colors.grey[100],
              },
            },
            ticks: {
              line: {
                stroke: colors.grey[100],
                strokeWidth: 1,
              },
              text: {
                fill: colors.grey[100],
                fontSize: isSmallScreen ? 10 : 12,
              },
            },
          },
          legends: {
            text: {
              fill: colors.grey[100],
              fontSize: isSmallScreen ? 10 : 12,
            },
          },
          tooltip: {
            container: {
              color: colors.primary[500],
            },
          },
        }}
        keys={["value"]}
        indexBy="Investor"
        margin={getMargin()}
        padding={0.1}
        innerPadding={1}
        layout={isSmallScreen ? "vertical" : "horizontal"}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={{ scheme: "nivo" }}
        defs={[
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "#38bcb2",
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "#eed312",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        borderColor={{
          from: "color",
          modifiers: [["darker", "1.6"]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={isSmallScreen ? {
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -45,
          legend: isDashboard ? undefined : "",
          legendPosition: "middle",
          legendOffset: 32
        } : null}
        axisLeft={{
          tickSize: 0,
          tickPadding: 7,
          tickRotation: 0,
          legend: isDashboard || isSmallScreen ? undefined : "",
          legendPosition: "middle",
          legendOffset: isSmallScreen ? -40 : isMediumScreen ? -80 : -150,
        }}
        enableGridY={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor="#000000"
        legends={getLegendConfig()}
      />
    </div>
  );
};

export default Investments;