import React, { useEffect, useState } from 'react';
import { ResponsivePie } from "@nivo/pie";
import { tokens } from "../theme";
import { useTheme, Box, useMediaQuery } from "@mui/material";
import api from "../api";
import { ClipLoader } from "react-spinners";

const RegionalStats = ({ isCustomLineColors = false, isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/africa-intermediate-region-deal-piechart/');
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const MyResponsivePie = ({ data }) => (
    <ResponsivePie
      data={data}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
        tooltip: {
          container: {
            color: colors.primary[500],
          },
        },
      }}
      colors={{ scheme: "nivo" }}
      margin={isSmallScreen ? { top: 10, right: 10, bottom: 10, left: 10 } : { top: 10, right: 80, bottom: 80, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      arcLinkLabelsSkipAngle={isSmallScreen ? 10 : 0}
      arcLinkLabelsTextColor={colors.grey[100]}
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsRadiusOffset={0.4}
      arcLabelsSkipAngle={isSmallScreen ? 15 : 10}
      arcLabelsTextColor={{
        from: 'color',
        modifiers: [['darker', 2]]
      }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: 'Western Africa'
          },
          id: 'dots'
        },
        {
          match: {
            id: 'Southern Africa'
          },
          id: 'dots'
        },
        {
          match: {
            id: 'Eastern Africa'
          },
          id: 'lines'
        },
        {
          match: {
            id: 'Other'
          },
          id: 'lines'
        },
      ]}
    />
  );

  return (
    <Box 
      position="relative" 
      display="flex" 
      flexDirection="column" 
      justifyContent="center" 
      alignItems="center" 
      width="100%" 
      height="100%"
      minHeight={isSmallScreen ? "200px" : "300px"}
    >
      {loading ? (
        <ClipLoader color="#7F1792"  />
      ) : (
        <>
          <Box width="100%" height="250px">
            <MyResponsivePie data={data} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default RegionalStats;