import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import { CssBaseline, ThemeProvider, Box } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import ReactGA from "react-ga4";
import { InfinitySpin } from "react-loader-spinner";
import { FeedbackButton } from 'pushfeedback-react';
import { defineCustomElements } from 'pushfeedback/loader';
import 'pushfeedback/dist/pushfeedback/pushfeedback.css';
// Components
import Sidebar from "./scenes/global/Sidebar";
import FloatActions from "./components/FloatActions";
import LoggedOut from "./auth/LoggedOut";

// Route components
import Dashboard from "./scenes/dashboard";
import Company from "./scenes/company";
import Investor from "./scenes/investors";
import CompanyForm from "./scenes/company-form";
import InvestorForm from "./scenes/investor-form";
import DealsForm from "./scenes/dealsform";
import Deals from "./scenes/deals";
import CompanySearch from "./scenes/search/companysearch";
import InvestorSearch from "./scenes/search/investorsearch";

import Feedback from "feeder-react-feedback"; // import Feedback component
import "feeder-react-feedback/dist/feeder-react-feedback.css"; // import stylesheet

const GA_TRACKING_ID = "G-QQ2P1ECK24";

function App() {
  const [theme, colorMode] = useMode();
  const { isLoading, isAuthenticated } = useKindeAuth();
  const location = useLocation();
  const [isGuest, setIsGuest] = useState(false); // State for guest access

  useEffect(() => {
    if (typeof window !== 'undefined') {
    defineCustomElements(window);
    }
}, []);

  useEffect(() => {
    ReactGA.initialize(GA_TRACKING_ID);
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <InfinitySpin color="#7F1792" />
      </Box>
    );
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box display="flex" height="100vh">
          {isAuthenticated || isGuest ? ( // Check for guest access
            <>
              <Sidebar />
              <Box component="main" flexGrow={1} overflow="auto" p={3}>
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/deals" element={<Deals />} />
                  <Route path="/company" element={<Company />} />
                  <Route path="/investors" element={<Investor />} />
                  <Route path="/company-form" element={<CompanyForm />} />
                  <Route path="/dealsform" element={<DealsForm />} />
                  <Route path="/investor-form" element={<InvestorForm />} />
                  <Route path="/companysearch" element={<CompanySearch />} />
                  <Route path="/investorsearch" element={<InvestorSearch />} />
                </Routes>
                {/* <FloatActions /> */}
                <Feedback projectId="66bb5bb05585fa0002b6e0b5" />;
                {/* <FeedbackButton project="00a0mm4x8r" button-position="center-right" modal-position="center-center" button-style="dark" hide-email="true"
        email-address="test@pushfeedback.com">Feedback</FeedbackButton> */}
              </Box>
            </>
          ) : (
            <LoggedOut onGuestAccess={() => setIsGuest(true)} /> // Pass the guest access handler
          )}
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
