import React from "react";
import SearchInvestor from "../../components/SearchInvestor";

const InvestorSearch = () => {
  return (
    <>
      <SearchInvestor />
    </>
  );
};

export default InvestorSearch;
