import { tokens } from "../theme";

export const CompanyTransactions = [
  {
    txId: "d.light",
    user: "Kenya",
    date: "18",
    cost: "284000000",
  },
  {
    txId: "Twiga Foods",
    user: "Kenya",
    date: "15",
    cost: "155350000",
  },
  {
    txId: "M-KOPA",
    user: "Kenya",
    date: "13",
    cost: "170400000",
  },
  {
    txId: "Zola Electric",
    user: "Tanzania",
    date: "12",
    cost: "308500000",
  },
  {
    txId: "Jumo",
    user: "South Africa",
    date: "10",
    cost: "266700000",
  },
  {
    txId: "Tugende",
    user: "Uganda",
    date: "9",
    cost: "50980000",
  },
  {
    txId: "Aerobotics",
    user: "South Africa",
    date: "8",
    cost: "21167000",
  },
  {
    txId: "Ampersand Electric Motorcycles",
    user: "Rwanda",
    date: "8",
    cost: "13405400",
  },
  {
    txId: "Bboxx",
    user: "Togo",
    date: "8",
    cost: "126200000",
  },
  {
    txId: "Daystar Power Africa",
    user: "Mauritius",
    date: "8",
    cost: "88560000",
  },
  {
    txId: "Eversend",
    user: "Uganda",
    date: "8",
    cost: "1274000",
  },
  {
    txId: "Flutterwave",
    user: "Nigeria",
    date: "8",
    cost: "475530000",
  },
  {
    txId: "PEG Africa",
    user: "Ivory Coast",
    date: "8",
    cost: "56540000",
  },
  {
    txId: "Releaf",
    user: "Nigeria",
    date: "8",
    cost: "7990000",
  },
  {
    txId: "ThankUCash",
    user: "Nigeria",
    date: "8",
    cost: "6160000",
  },
  {
    txId: "The Sun Exchange",
    user: "South Africa",
    date: "8",
    cost: "9732000",
  },
  {
    txId: "Thrive Agric",
    user: "Nigeria",
    date: "8",
    cost: "58560000",
  },
  {
    txId: "ANKA",
    user: "Nigeria",
    date: "7",
    cost: "8334000",
  },
  {
    txId: "Apollo Agriculture",
    user: "Kenya",
    date: "7",
    cost: "58100000",
  },
];



export const companyVCHQ = [
  { country: "CIV", amount: 37.0 },
  { country: "MAR", amount: 46.0 },
  { country: "RWA", amount: 53.0 },
  { country: "TUN", amount: 59.0 },
  { country: "GHA", amount: 143.0 },
  { country: "UGA", amount: 160.0 },

  { country: "EGY", amount: 488.0 },
  { country: "ZAF", amount: 491.0 },
  { country: "KEN", amount: 520.0 },
  { country: "NGA", amount: 680.0 },
];

export const InvestorDis = [
  { country: "ZMB", amount: 22.0 },
  { country: "ETH", amount: 24.0 },
  { country: "CIV", amount: 32.0 },
  { country: "RWA", amount: 38.0 },
  { country: "MAR", amount: 39.0 },
  { country: "TUN", amount: 53.0 },
  { country: "GHA", amount: 129.0 },
  { country: "UGA", amount: 141.0 },
  { country: "EGY", amount: 332.0 },
  { country: "ZAF", amount: 380.0 },
  { country: "KEN", amount: 416.0 },
  { country: "NGA", amount: 574.0 }
];

export const Investments = 
[
  { Investor: 'Cre Venture Capital', value: 22 },
  { Investor: 'Goodwell Investments', value: 23 },
  { Investor: 'Ventures Platform', value: 23 },
  { Investor: 'Founders Factory Africa', value: 28 },
  { Investor: 'Future Africa', value: 30 },
  { Investor: 'Alpha Code', value: 31 },
  { Investor: 'IFC', value: 34 },
  { Investor: '4Dx Ventures', value: 35 },
  { Investor: 'Techstars', value: 35 },
  { Investor: 'Flat6Labs', value: 41 },
  { Investor: '500 Startups', value: 55 },
  { Investor: 'Y Combinator', value: 102 }
];

export const DealFlow = [
  {
    id: "Twiga Foods",
    data: [
      { x: 2017, y: 0 },
      { x: 2018, y: 3 },
      { x: 2019, y: 3 },
      { x: 2020, y: 2 },
      { x: 2021, y: 1 },
      { x: 2022, y: 0 },
    ],
  },
  {
    id: "Aerobotics",
    data: [
      { x: 2017, y: 3 },
      { x: 2018, y: 1 },
      { x: 2019, y: 0 },
      { x: 2020, y: 3 },
      { x: 2021, y: 0 },
      { x: 2022, y: 0 },
    ],
  },
  {
    id: "Flutterwave",
    data: [
      { x: 2017, y: 3 },
      { x: 2018, y: 1 },
      { x: 2019, y: 0 },
      { x: 2020, y: 1 },
      { x: 2021, y: 1 },
      { x: 2022, y: 1 },
    ],
  },
  {
    id: "Tugende",
    data: [
      { x: 2017, y: 4 },
      { x: 2018, y: 1 },
      { x: 2019, y: 2 },
      { x: 2020, y: 1 },
      { x: 2021, y: 2 },
      { x: 2022, y: 1 },
    ],
  },
  {
    id: "d.light",
    data: [
      { x: 2017, y: 2 },
      { x: 2018, y: 3 },
      { x: 2019, y: 3 },
      { x: 2020, y: 0 },
      { x: 2021, y: 2 },
      { x: 2022, y: 2 },
    ],
  },
  {
    id: "Jumo",
    data: [
      { x: 2017, y: 3 },
      { x: 2018, y: 3 },
      { x: 2019, y: 0 },
      { x: 2020, y: 1 },
      { x: 2021, y: 1 },
      { x: 2022, y: 0 },
    ],
  },
];

export const RoundData = [
  {
    Round: "Crowdfunding",
    value: 24,
  },
  {
    Round: "Seed II",
    value: 31,
  },
  {
    Round: "Series C",
    value: 35,
  },
  {
    Round: "Corporate",
    value: 38,
  },
  {
    Round: "Private Equity",
    value: 54,
  },
  {
    Round: "Angel",
    value: 59,
  },

  {
    Round: "Pre-Series A",
    value: 74,
  },
  {
    Round: "Accelerator",
    value: 77,
  },
  {
    Round: "Series B",
    value: 87,
  },

  {
    Round: "Debt",
    value: 135,
  },
  {
    Round: "Series A",
    value: 282,
  },
  {
    Round: "Pre-Seed",
    value: 376,
  },
  {
    Round: "Grant",
    value: 752,
  },
  {
    Round: "Seed",
    value: 1136,
  },
];

export const comPieData = [
  {
    id: "Acquired",
    label: "Acquired",
    value: 47,
    color: "hsl(104, 70%, 50%)",
  },
  {
    id: "Active",
    label: "Active",
    value: 2726,
    color: "hsl(162, 70%, 50%)",
  },
  {
    id: "Closed",
    label: "Closed",
    value: 2,
    color: "hsl(291, 70%, 50%)",
  },
  {
    id: "Dead",
    label: "Dead",
    value: 8,
    color: "hsl(229, 70%, 50%)",
  },
  {
    id: "Others",
    label: "Others",
    value: 1,
    color: "hsl(344, 70%, 50%)",
  },
];

export const CompGeographyData = [
  // {
  //   id: "AFG",
  //   value: 0,
  // },
  {
    id: "AGO",
    value: 230000,
  },
  // {
  //   id: "ALB",
  //   value: 0,
  // },
  // {
  //   id: "ARE",
  //   value: 0,
  // },
  // {
  //   id: "ARG",
  //   value: 0,
  // },
  // {
  //   id: "ARM",
  //   value: 0,
  // },
  // {
  //   id: "ATA",
  //   value: 0,
  // },
  // {
  //   id: "ATF",
  //   value: 0,
  // },
  // {
  //   id: "AUT",
  //   value: 0,
  // },
  // {
  //   id: "AZE",
  //   value: 0,
  // },
  // {
  //   id: "BDI",
  //   value: 0,
  // },
  // {
  //   id: "BEL",
  //   value: 0,
  // },
  // {
  //   id: "BEN",
  //   value: 0,
  // },
  // {
  //   id: "BFA",
  //   value: 0,
  // },
  // {
  //   id: "BGD",
  //   value: 0,
  // },
  // {
  //   id: "BGR",
  //   value: 0,
  // },
  // {
  //   id: "BHS",
  //   value: 0,
  // },
  // {
  //   id: "BIH",
  //   value: 0,
  // },
  // {
  //   id: "BLR",
  //   value: 0,
  // },
  // {
  //   id: "BLZ",
  //   value: 0,
  // },
  // {
  //   id: "BOL",
  //   value: 0,
  // },
  // {
  //   id: "BRN",
  //   value: 0,
  // },
  // {
  //   id: "BTN",
  //   value: 0,
  // },
  // {
  //   id: "BWA",
  //   value: 0,
  // },
  // {
  //   id: "CAF",
  //   value: 0,
  // },
  // {
  //   id: "CAN",
  //   value: 0,
  // },
  // {
  //   id: "CHE",
  //   value: 0,
  // },
  // {
  //   id: "CHL",
  //   value: 0,
  // },
  // {
  //   id: "CHN",
  //   value: 0,
  // },
  // {
  //   id: "CIV",
  //   value: 0,
  // },
  {
    id: "CMR",
    value: 160000,
  },
  // {
  //   id: "COG",
  //   value: 0,
  // },
  // {
  //   id: "COL",
  //   value: 0,
  // },
  // {
  //   id: "CRI",
  //   value: 0,
  // },
  // {
  //   id: "CUB",
  //   value: 0,
  // },
  // {
  //   id: "-99",
  //   value: 0,
  // },
  // {
  //   id: "CYP",
  //   value: 0,
  // },
  // {
  //   id: "CZE",
  //   value: 0,
  // },
  // {
  //   id: "DEU",
  //   value: 0,
  // },
  // {
  //   id: "DJI",
  //   value: 0,
  // },
  // {
  //   id: "DNK",
  //   value: 0,
  // },
  // {
  //   id: "DOM",
  //   value: 0,
  // },
  // {
  //   id: "DZA",
  //   value: 0,
  // },
  // {
  //   id: "ECU",
  //   value: 0,
  // },
  {
    id: "EGY",
    value: 2600000,
  },
  // {
  //   id: "ERI",
  //   value: 0,
  // },
  // {
  //   id: "ESP",
  //   value: 0,
  // },
  // {
  //   id: "EST",
  //   value: 0,
  // },
  {
    id: "ETH",
    value: 140000,
  },
  // {
  //   id: "FIN",
  //   value: 0,
  // },
  // {
  //   id: "FJI",
  //   value: 0,
  // },
  // {
  //   id: "FLK",
  //   value: 0,
  // },
  // {
  //   id: "FRA",
  //   value: 0,
  // },
  // {
  //   id: "GAB",
  //   value: 0,
  // },
  // {
  //   id: "GBR",
  //   value: 0,
  // },
  // {
  //   id: "GEO",
  //   value: 0,
  // },
  {
    id: "GHA",
    value: 1350000,
  },
  // {
  //   id: "GIN",
  //   value: 0,
  // },
  // {
  //   id: "GMB",
  //   value: 0,
  // },
  // {
  //   id: "GNB",
  //   value: 0,
  // },
  // {
  //   id: "GNQ",
  //   value: 0,
  // },
  // {
  //   id: "GRC",
  //   value: 0,
  // },
  // {
  //   id: "GTM",
  //   value: 0,
  // },
  // {
  //   id: "GUY",
  //   value: 0,
  // },
  // {
  //   id: "HND",
  //   value: 0,
  // },
  // {
  //   id: "HRV",
  //   value: 0,
  // },
  // {
  //   id: "HTI",
  //   value: 0,
  // },
  // {
  //   id: "HUN",
  //   value: 0,
  // },
  // {
  //   id: "IDN",
  //   value: 0,
  // },
  // {
  //   id: "IND",
  //   value: 0,
  // },
  // {
  //   id: "IRL",
  //   value: 0,
  // },
  // {
  //   id: "IRN",
  //   value: 0,
  // },
  // {
  //   id: "IRQ",
  //   value: 0,
  // },
  // {
  //   id: "ISL",
  //   value: 0,
  // },
  // {
  //   id: "ISR",
  //   value: 0,
  // },
  // {
  //   id: "ITA",
  //   value: 0,
  // },
  // {
  //   id: "JAM",
  //   value: 0,
  // },
  // {
  //   id: "JOR",
  //   value: 0,
  // },
  // {
  //   id: "JPN",
  //   value: 0,
  // },
  // {
  //   id: "KAZ",
  //   value: 0,
  // },
  {
    id: "KEN",
    value: 4870000,
  },
  // {
  //   id: "KGZ",
  //   value: 0,
  // },
  // {
  //   id: "KHM",
  //   value: 0,
  // },
  // {
  //   id: "OSA",
  //   value: 0,
  // },
  // {
  //   id: "KWT",
  //   value: 0,
  // },
  // {
  //   id: "LAO",
  //   value: 0,
  // },
  // {
  //   id: "LBN",
  //   value: 0,
  // },
  // {
  //   id: "LBR",
  //   value: 0,
  // },
  // {
  //   id: "LBY",
  //   value: 0,
  // },
  // {
  //   id: "LKA",
  //   value: 0,
  // },
  // {
  //   id: "LSO",
  //   value: 0,
  // },
  // {
  //   id: "LTU",
  //   value: 0,
  // },
  // {
  //   id: "LUX",
  //   value: 0,
  // },
  // {
  //   id: "LVA",
  //   value: 0,
  // },
  {
    id: "MAR",
    value: 110000,
  },
  // {
  //   id: "MDA",
  //   value: 0,
  // },
  // {
  //   id: "MDG",
  //   value: 0,
  // },
  // {
  //   id: "MEX",
  //   value: 0,
  // },
  // {
  //   id: "MKD",
  //   value: 0,
  // },
  // {
  //   id: "MLI",
  //   value: 0,
  // },
  // {
  //   id: "MMR",
  //   value: 0,
  // },
  // {
  //   id: "MNE",
  //   value: 0,
  // },
  // {
  //   id: "MNG",
  //   value: 0,
  // },
  // {
  //   id: "MOZ",
  //   value: 0,
  // },
  // {
  //   id: "MRT",
  //   value: 0,
  // },
  // {
  //   id: "MWI",
  //   value: 0,
  // },
  // {
  //   id: "MYS",
  //   value: 0,
  // },
  // {
  //   id: "NAM",
  //   value: 0,
  // },
  // {
  //   id: "NCL",
  //   value: 0,
  // },
  // {
  //   id: "NER",
  //   value: 0,
  // },
  // {
  //   id: "NGA",
  //   value: 5840000,
  // },
  // {
  //   id: "NIC",
  //   value: 0,
  // },
  // {
  //   id: "NLD",
  //   value: 0,
  // },
  // {
  //   id: "NOR",
  //   value: 0,
  // },
  // {
  //   id: "NPL",
  //   value: 0,
  // },
  // {
  //   id: "NZL",
  //   value: 0,
  // },
  // {
  //   id: "OMN",
  //   value: 0,
  // },
  // {
  //   id: "PAK",
  //   value: 0,
  // },
  // {
  //   id: "PAN",
  //   value: 0,
  // },
  // {
  //   id: "PER",
  //   value: 0,
  // },
  // {
  //   id: "PHL",
  //   value: 0,
  // },
  // {
  //   id: "PNG",
  //   value: 0,
  // },
  // {
  //   id: "POL",
  //   value: 0,
  // },
  // {
  //   id: "PRI",
  //   value: 0,
  // },
  // {
  //   id: "PRT",
  //   value: 0,
  // },
  // {
  //   id: "PRY",
  //   value: 0,
  // },
  // {
  //   id: "QAT",
  //   value: 0,
  // },
  // {
  //   id: "ROU",
  //   value: 0,
  // },
  // {
  //   id: "RUS",
  //   value: 0,
  // },
  {
    id: "RWA",
    value: 200000,
  },
  {
    id: "ESH",
    value: 140000,
  },
  {
    id: "SAU",
    value: 6460000,
  },
  {
    id: "SDN",
    value: 170000,
  },
  {
    id: "SDS",
    value: 0,
  },
  {
    id: "SEN",
    value: 160000,
  },
  // {
  //   id: "SLB",
  //   value: 0,
  // },
  // {
  //   id: "SLE",
  //   value: 0,
  // },
  // {
  //   id: "SLV",
  //   value: 0,
  // },
  // {
  //   id: "ABV",
  //   value: 0,
  // },
  {
    id: "SOM",
    value: 0,
  },
  // {
  //   id: "SRB",
  //   value: 0,
  // },
  // {
  //   id: "SUR",
  //   value: 0,
  // },
  // {
  //   id: "SVK",
  //   value: 0,
  // },
  // {
  //   id: "SVN",
  //   value: 0,
  // },
  // {
  //   id: "SWZ",
  //   value: 0,
  // },
  // {
  //   id: "SYR",
  //   value: 0,
  // },
  {
    id: "TCD",
    value: 0,
  },
  {
    id: "TGO",
    value: 0,
  },
  // {
  //   id: "THA",
  //   value: 0,
  // },
  // {
  //   id: "TJK",
  //   value: 0,
  // },
  // {
  //   id: "TKM",
  //   value: 0,
  // },
  // {
  //   id: "TLS",
  //   value: 0,
  // },
  // {
  //   id: "TTO",
  //   value: 0,
  // },
  {
    id: "TUN",
    value: 250000,
  },
  {
    id: "TUR",
    value: 0,
  },
  {
    id: "TWN",
    value: 0,
  },
  {
    id: "TZA",
    value: 250000,
  },
  {
    id: "UGA",
    value: 620000,
  },
  // {
  //   id: "UKR",
  //   value: 0,
  // },
  {
    id: "URY",
    value: 0,
  },
  {
    id: "USA",
    value: 110000,
  },
  // {
  //   id: "UZB",
  //   value: 0,
  // },
  // {
  //   id: "VEN",
  //   value: 0,
  // },
  // {
  //   id: "VNM",
  //   value: 0,
  // },
  // {
  //   id: "VUT",
  //   value: 0,
  // },
  // {
  //   id: "PSE",
  //   value: 0,
  // },
  // {
  //   id: "YEM",
  //   value: 0,
  // },
  {
    id: "ZAF",
    value: 6460000,
  },
  {
    id: "ZMB",
    value: 140000,
  },
  {
    id: "ZWE",
    value: 140000,
  },
  // {
  //   id: "KOR",
  //   value: 0,
  // },
];

export const pressTransactions = [
  {
    txId: "Zuvy",
    user: "Nigeria",
    date: "2023-07-04",
    cost: "4,500,000",
  },
  {
    txId: "Eze",
    user: "Nigeria",
    date: "2023-06-20",
    cost: "3,700,000",
  },
  {
    txId: "Yodawy",
    user: "Egypt",
    date: "2023-02-10",
    cost: "16,000,000",
  },
  {
    txId: "Carry1st",
    user: "Nigeria",
    date: "2023-01-25",
    cost: "27,000,000",
  },
  {
    txId: "Moja Ride",
    user: "Ivory Coast",
    date: "2023-01-24",
    cost: "18,000",
  },
  {
    txId: "Flow",
    user: "South Africa",
    date: "2023-01-20",
    cost: "4,500,000",
  },
  {
    txId: "Kwara",
    user: "Kenya",
    date: "2023-01-17",
    cost: "3,000,000",
  },
  {
    txId: "KarmSolar",
    user: "Egypt",
    date: "2023-01-12",
    cost: "3,000,000",
  },
  {
    txId: "Agro Supply Ltd",
    user: "Uganda",
    date: "2023-01-11",
    cost: "200,000",
  },
  {
    txId: "Bekia",
    user: "Egypt",
    date: "2023-01-11",
    cost: "200,000",
  },
  {
    txId: "Farmz2U",
    user: "Nigeria",
    date: "2023-01-11",
    cost: "200,000",
  },
  {
    txId: "Jetstream Africa",
    user: "Ghana",
    date: "2023-01-10",
    cost: "13,000,000",
  },
  {
    txId: "Releaf",
    user: "Nigeria",
    date: "2023-01-10",
    cost: "3,300,000",
  },
];
