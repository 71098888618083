import React, { useState } from "react";
import api from "../../api";
import {
  Box,
  Button,
  TextField,
  CircularProgress,
  Select,
  MenuItem,
} from "@mui/material";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DealsForm = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    company_name: "",
    when: "",
    amount: "",
    funding_round: "",
    source: "",
    investors: "",
    country: "",
    currency: "",
  };

  const onSubmit = async (values, { setErrors }) => {
    try {
      setIsLoading(true);
      const company_name = values.company_name;
      await api.post(`/company/deals/${company_name}`, values);
      console.log(values);
      formik.resetForm();
      toast.success("Deal created successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } catch (error) {
      if (error.response && error.response.data) {
        // If the API returns detailed error messages, set them as form errors
        setErrors(error.response.data.detail);
        toast.error(error.response.data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        // Generic error handling
        console.error("API Error:", error);
        // You can display a generic error message to the user if needed
        setErrors({ api: "An error occurred while processing your request." });
        toast.error("An error occurred while processing your request.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const validationSchema = yup.object({
    company_name: yup.string().required("Company name is required"),
    when: yup.string().required(),
    amount: yup.string().required("Amount is required"),
    currency: yup.string().required("Currency is required"),
    country: yup.string().required("Country is required"),
    funding_round: yup.string().required("Funding round is required"),
    source: yup.string().required("source is required"),
    investors: yup.string().required("Investors is required"),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  console.log(formik.errors);

  return (
    <Box m="20px">
      <Header
        title="ADD YOUR DEAL"
        subtitle="lets document your journey to success"
      />
      {isLoading && (
        <Box display="flex" justifyContent="center" mt="20px">
          <CircularProgress />
        </Box>
      )}
      <Formik
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="COMPANY NAME"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.company_name}
              name="company_name"
              error={
                !!formik.touched.company_name && !!formik.errors.company_name
              }
              helperText={
                formik.touched.company_name && formik.errors.company_name
              }
              sx={{ gridColumn: "span 1" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="date"
              label="when"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.when}
              name="when"
              error={!!formik.touched.when && !!formik.errors.when}
              helperText={formik.touched.when && formik.errors.when}
              sx={{ gridColumn: "span 1" }}
            />
            
            <TextField
              fullWidth
              variant="filled"
              type="number"
              label="AMOUNT"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.amount}
              name="amount"
              error={!!formik.touched.amount && !!formik.errors.amount}
              helperText={formik.touched.amount && formik.errors.amount}
              sx={{ gridColumn: "span 1" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="CURRENCY"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.currency}
              name="currency"
              error={!!formik.touched.currency && !!formik.errors.currency}
              helperText={formik.touched.currency && formik.errors.currency}
              sx={{ gridColumn: "span 1" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="COUNTRY"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.country}
              name="country"
              error={!!formik.touched.country && !!formik.errors.country}
              helperText={formik.touched.country && formik.errors.country}
              sx={{ gridColumn: "span 1" }}
            />
            {/*<Select
              fullWidth
              variant="filled"
              type="text"
              label="SELECTION"
              value={formik.values.selection}
              name="selection"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={!!formik.touched.selection && !!formik.errors.selection}
              helperText={formik.touched.selection && formik.errors.selection}
              sx={{ gridColumn: "span 1" }}
            >
              <MenuItem value="">Option 1</MenuItem>
              <MenuItem value="">Option 2</MenuItem>
              <MenuItem value="">Option 3</MenuItem>
            </Select>*/}
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="FUNDING ROUND"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.funding_round}
              name="funding_round"
              error={
                !!formik.touched.funding_round && !!formik.errors.funding_round
              }
              helperText={
                formik.touched.funding_round && formik.errors.funding_round
              }
              sx={{ gridColumn: "span 1" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="SOURCE"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.source}
              name="source"
              error={!!formik.touched.source && !!formik.errors.source}
              helperText={formik.touched.source && formik.errors.source}
              sx={{ gridColumn: "span 1" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="INVESTORS"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.investors}
              name="investors"
              error={!!formik.touched.investors && !!formik.errors.investors}
              helperText={formik.touched.investors && formik.errors.investors}
              sx={{ gridColumn: "span 1" }}
            />
          </Box>
          <Box display="flex" justifyContent="end" mt="20px">
            <Button type="submit" color="secondary" variant="contained">
              Create New Deal
            </Button>
          </Box>
        </form>
      </Formik>
      <ToastContainer />
    </Box>
  );
};

export default DealsForm;
