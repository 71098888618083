import { useState } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SideBarHeader from "./SidebarHeader";
import SideBarFooter from "./SidebarFooter";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.white} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#7F1792 !important",
        },
        "& .pro-menu-item.active": {
          color: "#7F1792 !important",
        },
        "& .pro-sidebar": {
          width: isCollapsed ? "90px !important" : "200px !important",
          minWidth: isCollapsed ? "90px !important" : "200px !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {/* Sidebar Header */}
          <SideBarHeader isCollapsed={isCollapsed} />

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {/* Quick Search Section */}
            <SubMenu title="Quick Search" style={{
              color: colors.grey[100],
            }} icon={<SearchOutlinedIcon/>} selected={selected} setSelected={setSelected}>
              <MenuItem icon={<PeopleOutlinedIcon/>}>
                <Link to="/investorsearch">Investor Search</Link>
              </MenuItem>
              <MenuItem icon={<AccountBalanceIcon/>}>
                <Link to="/companysearch">Company Search</Link>
              </MenuItem>
            </SubMenu>

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Descriptive Analysis
            </Typography>
            <Item
              title="Deals"
              to="/deals"
              icon={<ImportExportIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Company"
              to="/company"
              icon={<AccountBalanceIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Investors"
              to="/investors"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {/* Account */}
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Account Details
            </Typography>
            <SideBarFooter isCollapsed={isCollapsed} />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;