import React, { useState, useEffect } from "react";
import api from "../api";
import StatBox from "../components/StatBox";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../theme";
import "react-loading-skeleton/dist/skeleton.css";
import FunctionsIcon from "@mui/icons-material/Functions";
//import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import {ClipLoader} from "react-spinners"
import { Box } from "@mui/material";
const AverageInvestment = () => {
    const [averageInvestment, setAverageInvestment] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    useEffect(() => {
        // Fetch average investments from the backend
        api
            .get("/investment-statistics/")
            .then((response) => {
                setAverageInvestment(response.data.average_investment);
            })
            .catch((error) => {
                console.error("Error fetching average investments:", error);
                setError("Error fetching average investments. Please try again later.");
            })
            .finally(() => {
                setLoading(false); // Set loading to false once data is received or an error occurs
            });
    }, []);
  return (
    <Box
    sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        padding: '20px',
      }}
    >
        {error && <p style={{ color: "red" }}>{error}</p>}
    
        {loading ? (
           <Box display="flex" justifyContent="center" alignItems="center">
           {loading && <ClipLoader color="#7F1792" loading={loading} />}
         </Box>
        ) : (
            averageInvestment !== null && !error && (
            <StatBox
                title={`$${averageInvestment}M`}
                subtitle="Average Investment"
                icon={
                <FunctionsIcon
                    sx={{ color: colors.parent_palatte[400], fontSize: "26px" }}
                />
                }
            />
            )
        )}
    </Box>
  )
}

export default AverageInvestment;