import React, { useEffect, useState } from "react";
import { useTheme, Box } from "@mui/material";
import { ResponsiveChoropleth } from "@nivo/geo";
import { geoFeatures } from "../data/mockGeoFeatures";
import { tokens } from "../theme";
import api from "../api";
import { ClipLoader } from "react-spinners";

const MapAmount = ({ isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/country-deal-counts/");
        setData(response.data);
      } catch (error) {
        console.error("Error fetching the data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const minValue = Math.min(...data.map(d => d.value));
  const maxValue = Math.max(...data.map(d => d.value));

  // Dynamic fill generation based on the data
  const generateFills = () => {
    return data.map((item) => {
      let fillId = '';

      if (item.value > maxValue * 0.75) {
        fillId = 'gradient';
      } else if (item.value > maxValue * 0.5) {
        fillId = 'lines';
      } else if (item.value > maxValue * 0.25) {
        fillId = 'dots';
      } else {
        fillId = 'none';
      }

      return {
        match: {
          id: item.id,
        },
        id: fillId,
      };
    });
  };

  return (
    <Box position="relative" display="flex" justifyContent="center" alignItems="center" height={isDashboard ? "300px" : "100%"}>
      {loading ? (
        <ClipLoader color="#7F1792"  />
      ) : (
        <ResponsiveChoropleth
          data={data}
          theme={{
            axis: {
              domain: {
                line: {
                  stroke: colors.grey[100],
                },
              },
              legend: {
                text: {
                  fill: colors.grey[100],
                },
              },
              ticks: {
                line: {
                  stroke: colors.grey[100],
                  strokeWidth: 1,
                },
                text: {
                  fill: colors.grey[100],
                },
              },
            },
            legends: {
              text: {
                fill: colors.grey[100],
              },
            },
            tooltip: {
              container: {
                color: colors.primary[500],
              },
            },
          }}
          features={geoFeatures.features}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          domain={[minValue, maxValue]}
          unknownColor="#666666"
          label="properties.name"
          valueFormat=".2s"
          colors="nivo"
          projectionScale={120}
          projectionType="equirectangular"
          projectionTranslation={isDashboard ? [0.49, 0.6] : [0.5, 0.5]}
          projectionRotation={[0, 0, 0]}
          borderWidth={1.5}
          borderColor="#152538"
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: colors.primary[300],
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: colors.primary[500],
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
            {
              id: 'gradient',
              type: 'linearGradient',
              colors: [
                { offset: 0, color: colors.primary[600] },
                { offset: 100, color: 'inherit' },
              ],
            },
          ]}
          fill={generateFills()}
          legends={
            !isDashboard
              ? [
                  {
                    anchor: "bottom-left",
                    direction: "column",
                    justify: true,
                    translateX: 5,
                    translateY: -30,
                    itemsSpacing: 0,
                    itemWidth: 94,
                    itemHeight: 18,
                    itemDirection: "left-to-right",
                    itemTextColor: colors.grey[100],
                    itemOpacity: 0.85,
                    symbolSize: 18,
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemTextColor: '#000000',
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]
              : undefined
          }
        />
      )}
    </Box>
  );
};

export default MapAmount;
