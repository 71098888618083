import React from 'react';
import { Box, Button } from "@mui/material";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

const Blurred = ({ children }) => {

  const { isAuthenticated, login } = useKindeAuth();

  return (
    <Box position="relative">
      <Box
        style={{
          filter: isAuthenticated ? 'none' : 'blur(5px)',
          pointerEvents: isAuthenticated ? 'auto' : 'none',
          
        }}
      >
        {children}
      </Box>
      {!isAuthenticated && (
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex={1}
          style={{ backdropFilter: 'blur(5px)' }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={login}
          >
            Sign In
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Blurred;
