import React, { useEffect, useState } from "react";
import { useTheme,useMediaQuery, Box} from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../theme";
import api from "../api";
import { ClipLoader } from "react-spinners";

const SectorCount = ({ isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const getMargin = () => {
    if (isSmallScreen) {
      return { top: 0, right: 10, bottom: 20, left: 50 };
    } else if (isMediumScreen) {
      return { top: 5, right: 80, bottom: 30, left: 60 };
    } else {
      return { top: 10, right: 130, bottom: 40, left: 300 };
    }
  };

  const getLegendConfig = () => {
    if (isSmallScreen) {
      return [];
    } else {
      return [
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: isMediumScreen ? 80 : 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ];
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/top-sectors/");
        const last_ten = response.data.slice(-12);
        setData(last_ten);
      } catch (error) {
        console.error("Error fetching the data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const MyResponsiveBar = ({ data }) => (
    <div style={{ height: isDashboard ? '300px' : '100%', minHeight: '200px' }}>
      <ResponsiveBar
      data={data}
      theme={{
        axis: {
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[500],
              fontSize: isSmallScreen ? 10 : 12,
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
            fontSize: isSmallScreen ? 10 : 12,
          },
        },
        tooltip: {
          container: {
            color: colors.primary[500],
          },
        },
      }}
      keys={["total"]}
      indexBy="sector"
      margin={getMargin()}
      padding={0.1}
      innerPadding={0}
      
      layout={isSmallScreen ? "vertical" : "horizontal"}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band" }}
      colors={{ scheme: "nivo" }}
      axisTop={null}
      axisRight={null}
      axisBottom={isSmallScreen ? {
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -45,
        legend: isDashboard ? undefined : "",
        legendPosition: "middle",
        legendOffset: 32
      } : null}
      axisLeft={{
        tickSize: 0,
        tickPadding: 7,
        tickRotation: 0,
        legend: isDashboard || isSmallScreen ? undefined : "Sectors",
        legendPosition: "middle",
        legendOffset: -280,
      }}
      labelSkipWidth={30}
      labelSkipHeight={0}
      enableGridY={false}
      labelTextColor="#000000"
      legends={getLegendConfig()}
      enableLabel={!isSmallScreen}

    />
    </div>
    
  );

  return (
    <Box position="relative" display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="230px">
      {loading ? (
        <ClipLoader color="#7F1792"  />
      ) : (
        <>
          <Box width="100%" height="250px">
            <MyResponsiveBar data={data}/>
          </Box>
        </>
      )}
    </Box>
  );
};

export default SectorCount;
