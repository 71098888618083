import styled from "@emotion/styled";
import React from "react";
import { Typography, Button, Box, Tooltip } from "@mui/material";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme"; // Adjust the import based on your tokens file path

const StyledSidebarFooter = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 8px;
  color: white;
  background: rgb(236,250,255);
  background: linear-gradient(137deg, rgba(236,250,255,1) 0%, rgba(185,236,251,1) 80%);
`;

const StyledCollapsedSidebarFooter = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
  }
`;

const SideBarFooter = ({ children, isCollapsed, ...rest }) => {
  const { user, logout } = useKindeAuth();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const renderAvatar = (size) => (
    user?.picture ? (
      <img
        className="avatar"
        src={user.picture}
        alt="user profile avatar"
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
    ) : (
      <Box
        className="avatar"
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: colors.white,
          color: colors.black,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: size / 2,
          fontWeight: 700
        }}
      >
        {user ? (
          <>
            {user.given_name?.[0]}
            {user.family_name?.[0]}
          </>
        ) : (
          "G"
        )}
      </Box>
    )
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        paddingBottom: "20px",
      }}
    >
      {isCollapsed ? (
        <Tooltip title="Click to logout" placement="right">
          <StyledCollapsedSidebarFooter onClick={logout}>
            {renderAvatar(40)}
          </StyledCollapsedSidebarFooter>
        </Tooltip>
      ) : (
        <StyledSidebarFooter {...rest}>
          <div style={{ marginBottom: "12px", width: 50, height: 50, borderRadius: '50%', overflow: 'hidden' }}>
            {renderAvatar(50)}
          </div>
          <Typography fontWeight={600} color={colors.black}>
            {user ? `${user.given_name} ${user.family_name}` : "Guest User"}
          </Typography>
          {user && (
            <Button
              variant="contained"
              size="medium"
              sx={{ mt: 1, borderRadius: 5, background: 'rgb(2,0,36) linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(127,23,146,1) 35%, rgba(0,212,255,1) 100%)' }}
              onClick={logout}
            >
              Sign out
            </Button>
          )}
        </StyledSidebarFooter>
      )}
    </div>
  );
};

export default SideBarFooter;
