import styled from '@emotion/styled';
import React from 'react';
import { Typography } from "@mui/material";

const StyledSidebarHeader = styled.div`
  height: 64px;
  min-height: 64px;
  display: flex;
  align-items: center;
  padding: 0 20px;

  > div {
    width: 100%;
    overflow: hidden;
  }
`;

const StyledLogo = styled.div`
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: white;
  font-size: 24px;
  font-weight: 700;
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(127,23,146,1) 35%, rgba(0,212,255,1) 100%);
  margin-right: ${({ isCollapsed }) => (isCollapsed ? '0' : '10px')};
  margin-left: 4px;
`;

const SideBarHeader = ({ children, isCollapsed, rtl, ...rest }) => {
  return (
    <StyledSidebarHeader {...rest}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <StyledLogo isCollapsed={isCollapsed}>da</StyledLogo>
        {!isCollapsed && (
          <Typography fontWeight={700} color="#7F1792">
            Digest Africa
          </Typography>
        )}
      </div>
    </StyledSidebarHeader>
  );
};

export default SideBarHeader;