import React , { useState }  from "react";
import api from "../../api";
import { Box, Button, TextField , CircularProgress  } from "@mui/material";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CompanyForm = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    company_name: "",
    description: "",
    founded: "",
    founders: "",
    country: "",
    headquarters: "",
    website: "",
    industry: "",
    sector: "",
    countries_of_operation: "",
  };

  const onSubmit = async (values, { setErrors }) => {
    try {
      setIsLoading(true);
      await api.post("/create_company/", values);
      console.log(values);
      formik.resetForm();
      toast.success("Company created successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
    catch (error) {
      if (error.response && error.response.data) {
        // If the API returns detailed error messages, set them as form errors
        setErrors(error.response.data.detail);
        toast.error(error.response.data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        // Generic error handling
        console.error("API Error:", error);
        // You can display a generic error message to the user if needed
        setErrors({ api: "An error occurred while processing your request." });
        toast.error("An error occurred while processing your request.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const validationSchema = yup.object({
    company_name: yup.string().required("Company name is required"),
    description: yup.string(),
    founded: yup.string(),
    founders: yup.string(),
    country: yup.string().required("Country is required"),
    headquarters: yup.string(),
    website: yup.string().required("Website is required"),
    industry: yup.string().required("Industry is required"),
    sector: yup.string().required("Sector is required"),
    countries_of_operation: yup.string(),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  console.log(formik.errors);

  return (
    <Box m="20px">
      <Header
        title="ADD YOUR Company"
        subtitle="lets document your journey to success"
      />
      {isLoading && (
        <Box display="flex" justifyContent="center" mt="20px">
          <CircularProgress />
        </Box>
      )}
      <Formik
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="COMPANY NAME"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.company_name}
              name="company_name"
              error={
                !!formik.touched.company_name && !!formik.errors.company_name
              }
              helperText={
                formik.touched.company_name && formik.errors.company_name
              }
              sx={{ gridColumn: "span 1" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="DESCRIPTION"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.description}
              name="description"
              error={
                !!formik.touched.description && !!formik.errors.description
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              sx={{ gridColumn: "span 1" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="date"
              label="FOUNDED"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.founded}
              name="founded"
              error={!!formik.touched.founded && !!formik.errors.founded}
              helperText={formik.touched.founded && formik.errors.founded}
              sx={{ gridColumn: "span 1" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="FOUNDERS"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.founders}
              name="founders"
              error={!!formik.touched.founders && !!formik.errors.founders}
              helperText={formik.touched.founders && formik.errors.founders}
              sx={{ gridColumn: "span 1" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="COUNTRY"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.country}
              name="country"
              error={!!formik.touched.country && !!formik.errors.country}
              helperText={formik.touched.country && formik.errors.country}
              sx={{ gridColumn: "span 1" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="HEADQUARTERS"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.headquarters}
              name="headquarters"
              error={
                !!formik.touched.headquarters && !!formik.errors.headquarters
              }
              helperText={
                formik.touched.headquarters && formik.errors.headquarters
              }
              sx={{ gridColumn: "span 1" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="WEBSITE"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.website}
              name="website"
              error={!!formik.touched.website && !!formik.errors.website}
              helperText={formik.touched.website && formik.errors.website}
              sx={{ gridColumn: "span 1" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="industry"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.industry}
              name="industry"
              error={!!formik.touched.industry && !!formik.errors.industry}
              helperText={formik.touched.industry && formik.errors.industry}
              sx={{ gridColumn: "span 1" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="SECTOR"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.sector}
              name="sector"
              error={!!formik.touched.sector && !!formik.errors.sector}
              helperText={formik.touched.sector && formik.errors.sector}
              sx={{ gridColumn: "span 1" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="COUNTRIES OF OPERATION"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.countries_of_operation}
              name="countries_of_operation"
              error={
                !!formik.touched.countries_of_operation &&
                !!formik.errors.countries_of_operation
              }
              helperText={
                formik.touched.countries_of_operation &&
                formik.errors.countries_of_operation
              }
              sx={{ gridColumn: "span 1" }}
            />
          </Box>
          <Box display="flex" justifyContent="end" mt="20px">
            <Button type="submit" color="secondary" variant="contained">
              Create New Deal
            </Button>
          </Box>
        </form>
      </Formik>
      <ToastContainer />
    </Box>
  );
};

export default CompanyForm;
