import React from "react";
import Search from "../../components/Search";
const CompanySearch = () => {
  return (
    <>
      <Search />
    </>
  );
};

export default CompanySearch;
