import React from 'react';
import { ResponsiveAreaBump } from '@nivo/bump';
import { useTheme } from '@mui/material';
import { tokens } from '../theme';
import { DealFlow as data } from '../data/companyData';
import { useMediaQuery } from '@mui/material';

const DealFlow = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const chartTheme = {
    axis: {
      domain: {
        line: {
          stroke: colors.grey[100],
        },
      },
      legend: {
        text: {
          fill: colors.grey[100],
        },
      },
      ticks: {
        line: {
          stroke: colors.grey[100],
          strokeWidth: 1,
        },
        text: {
          fill: colors.grey[100],
        },
      },
    },
    legends: {
      text: {
        fill: colors.grey[100],
      },
    },
    tooltip: {
      container: {
        background: colors.primary[400],
        color: colors.grey[100],
        fontSize: '12px',
        borderRadius: '4px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      },
    },
  };

  return (
    <div style={{ height: isMobile ? '200px' : '300px', width: '100%' }}>
      <ResponsiveAreaBump
        data={data}
        theme={chartTheme}
        margin={{ 
          top: isMobile ? 5 : 10, 
          right: isMobile ? 50 : 100, 
          bottom: isMobile ? 50 : 40, 
          left: isMobile ? 50 : 100 
        }}
        spacing={isMobile ? 4 : 8}
        colors={{ scheme: 'category10' }}
        blendMode="multiply"
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: '#38bcb2',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: '#eed312',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        fill={[
          {
            match: {
              id: 'Tugende',
            },
            id: 'dots',
          },
          {
            match: {
              id: 'd.light',
            },
            id: 'lines',
          },
        ]}
        startLabel={isMobile ? null : 'id'}
        endLabel={isMobile ? null : 'id'}
        axisTop={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: isMobile ? -45 : 0,
          legend: '',
          legendPosition: 'middle',
          legendOffset: 32,
        }}
        animate={true}
        motionConfig="gentle"
        enableGridX={false}
        enableGridY={false}
        inactiveOpacity={0.15}
        borderWidth={1}
        borderColor={{ theme: 'background' }}
      />
    </div>
  );
};

export default DealFlow;