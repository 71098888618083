import React, { useState, useEffect } from 'react';
import api from '../api';
import StatBox from '../components/StatBox';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import { useTheme } from '@mui/material/styles';
import { tokens } from '../theme';
//import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { ClipLoader} from "react-spinners"
import { Box } from "@mui/material";

const TotalDeals = () => {
  const [totalDeals, setTotalDeals] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    // Fetch total deals from the backend
    api.get('/african-deal-count/')
      .then(response => {
        setTotalDeals(response.data.african_deal_count);
      })
      .catch(error => {
        console.error('Error fetching total deals:', error);
        setError('Error fetching total deals. Please try again later.');
      })
      .finally(() => {
        setLoading(false); // Set loading to false once data is received or an error occurs
      });
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        padding: '20px',
      }}
    >
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
        {loading && <ClipLoader color="#7F1792" loading={loading} />}
      </Box>
        
      ) : (
        totalDeals !== null && !error && (
          <StatBox
            title={`${totalDeals}`}
            subtitle="Total No. of deals"
            icon={
              <AlignVerticalBottomIcon
                sx={{ color: colors.parent_palatte[400], fontSize: '26px' }}
              />
            }
          />
        )
      )}
    </Box>
  );
};

export default TotalDeals;