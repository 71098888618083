import React, { useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import { BarLoader } from "react-spinners";
import backgroundImage from "../images/company3.webp";
import SearchBar from "material-ui-search-bar";

const Search_Company = gql`
  query Search_Company($company_name: String!) {
    companies(where: { company_name_CONTAINS: $company_name }) {
      countryLocatedIncountries {
        country
      }
      fundingRoundReceivedfundingrounds {
        funding_round_received
      }
      investorsinvestedIn {
        investors_name
      }
      sectorInmainsectors {
        sector
      }
      company_name
    }
  }
`;

export default function Search() {
  const [company_name, setCompanyName] = useState("");

  const [getCompanies, { loading, error, data }] = useLazyQuery(
    Search_Company,
    {
      variables: { company_name },
    }
  );

  const columns = [
    { field: "company_name", headerName: "Company Name", width: 200 },
    { field: "country", headerName: "Country", width: 200 },
    { field: "sector", headerName: "Sector", width: 200 },
    {
      field: "funding_round_received",
      headerName: "Funding Rounds Received",
      width: 200,
    },
    { field: "investors_name", headerName: "Investors", width: 200 },
  ];

  const rows =
    data?.companies.map((company, index) => {
      // Add error checking here
      const country =
        company.countryLocatedIncountries &&
        company.countryLocatedIncountries.length > 0
          ? company.countryLocatedIncountries.map((c) => c.country).join(", ")
          : null;
      const sector =
        company.sectorInmainsectors && company.sectorInmainsectors.length > 0
          ? company.sectorInmainsectors.map((s) => s.sector).join(", ")
          : null;

      return {
        id: index,
        company_name: company.company_name,
        country: country,
        sector: sector,
        funding_round_received: company.fundingRoundReceivedfundingrounds
          .map((round) => round.funding_round_received)
          .join(", "), // Concatenate funding rounds into a comma-separated string
        investors_name: company.investorsinvestedIn
          .map((investor) => investor.investors_name)
          .join(", "), // Concatenate investors into a comma-separated string
      };
    }) || [];

  return (
    <>
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          getCompanies();
        }}
        sx={{
          ml: 3,
          padding: 1,
          width: "60%",
          mb: 2,
        }}
      >
        <SearchBar
          value={company_name}
          onChange={(newValue) => setCompanyName(newValue)}
          onRequestSearch={() => getCompanies()}
          placeholder="Search for a company..."
        />
      </Box>

      <Box
        sx={{
          ml: 4,
          padding: 1,
          borderRadius: 3,
          backgroundColor: "background.paper",
          width: "85%",
          height: "80%",
          backgroundImage: data ? "none" : `url(${backgroundImage})`,
          backgroundSize: "cover",
        }}
      >
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <BarLoader color="#9c27b0" loading={loading} />
          </Box>
        )}
        {error && <Alert severity="error">{error.message}</Alert>}
        {/* Display error message */}
        {data && data.companies.length === 0 && (
          <Alert severity="info">No records found</Alert>
        )}{" "}
        {/* Display no records message */}
        {data && data.companies.length > 0 && (
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{ pagination: { paginationModel: { pageSize: 15 } } }}
            disableRowSelectionOnClick
            checkboxSelection
            {...data}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                  printOptions: { disableToolbarButton: true },
                  csvOptions: { disableToolbarButton: true },
                  excelOptions: { disableToolbarButton: true },
                
          }}}
            pageSizeOptions={[5, 10, 15, 20, 25]}
          />
        )}
      </Box>
    </>
  );
}
