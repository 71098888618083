import React, { useState, useEffect } from "react";
import api from "../api";
import StatBox from "../components/StatBox";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../theme";
import "react-loading-skeleton/dist/skeleton.css";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { ClipLoader } from "react-spinners";
import { Box } from "@mui/material";

const LargestInvestment = () => {
    const [largestInvestment, setLargestInvestment] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
        // Fetch investment statistics from the backend
        api
            .get("/investment-statistics/")
            .then((response) => {
                setLargestInvestment(response.data.largest_investment);
            })
            .catch((error) => {
                console.error("Error fetching investment statistics:", error);
                setError("Error fetching investment statistics. Please try again later.");
            })
            .finally(() => {
                setLoading(false); // Set loading to false once data is received or an error occurs
            });
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                padding: '20px',
            }}>
            {error && <p style={{ color: "red" }}>{error}</p>}
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                    {loading && <ClipLoader color="#7F1792" loading={loading} />}
                </Box>
            ) : (
                largestInvestment !== null && !error && (
                    <StatBox
                        title={`$${largestInvestment}M`}
                        subtitle="Largest Investment"
                        icon={
                            <MonetizationOnIcon
                                sx={{ color: colors.parent_palatte[400], fontSize: "26px" }}
                            />
                        }
                    />
                )
            )}
        </Box>
    );
};

export default LargestInvestment;
