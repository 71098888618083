"use client";
import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  message as antdMessage,
  Input,
  Button,
  Space,
  AutoComplete,
  Tooltip,
} from "antd";
import api from "../api";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { Box } from "@mui/material";
import * as XLSX from "xlsx";
import { DownloadOutlined, FileExcelOutlined } from "@ant-design/icons";

const getColumnSearchProps = (
  dataIndex,
  searchText,
  searchedColumn,
  handleSearch,
  handleReset,
  searchInput,
  autoCompleteFilteredOptions,
  setAutoCompleteFilteredOptions
) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <AutoComplete
        options={(autoCompleteFilteredOptions[dataIndex] || []).map(
          (option) => ({
            value: option,
          })
        )}
        style={{ width: "100%", marginBottom: 8 }}
        onSelect={(val) => {
          setSelectedKeys([val]);
          handleSearch([val], confirm, dataIndex);
        }}
        onSearch={(val) => {
          const filteredOptions = (
            autoCompleteFilteredOptions[dataIndex] || []
          ).filter((option) =>
            option.toLowerCase().includes(val.toLowerCase())
          );
          setAutoCompleteFilteredOptions((prev) => ({
            ...prev,
            [dataIndex]: filteredOptions,
          }));
        }}
        value={selectedKeys[0]}
        onChange={(val) => {
          setSelectedKeys(val ? [val] : []);
          if (!val) {
            handleReset(clearFilters);
          }
        }}
      >
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
      </AutoComplete>
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : "",
  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.current.select(), 100);
    }
  },
  render: (text) =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ) : (
      <Tooltip title={text}>
        <span className="ellipsis-text">{text || "No data"}</span>
      </Tooltip>
    ),
});

const columns = (
  searchText,
  searchedColumn,
  handleSearch,
  handleReset,
  searchInput,
  autoCompleteFilteredOptions,
  setAutoCompleteFilteredOptions
) => [
  {
    title: "Date",
    dataIndex: "when",
    key: "when",
    width: 120,
    sorter: (a, b) => new Date(a.when) - new Date(b.when),
    render: (date) => moment(date).format("MMMM Do YYYY"),
    ...getColumnSearchProps(
      "when",
      searchText,
      searchedColumn,
      handleSearch,
      handleReset,
      searchInput,
      autoCompleteFilteredOptions,
      setAutoCompleteFilteredOptions
    ),
  },
  {
    title: "Company",
    dataIndex: "company_mentioned",
    key: "company_mentioned",
    width: 150,
    ...getColumnSearchProps(
      "company_mentioned",
      searchText,
      searchedColumn,
      handleSearch,
      handleReset,
      searchInput,
      autoCompleteFilteredOptions,
      setAutoCompleteFilteredOptions
    ),
    sorter: (a, b) => a.company_mentioned.localeCompare(b.company_mentioned),
  },
  {
    title: "Amount(USD)",
    width: 120,
    dataIndex: "usd_amount",
    key: "usd_amount",
    sorter: (a, b) => a.usd_amount - b.usd_amount,
  },
  {
    title: "Funding Round",
    dataIndex: "type_of_funding_round",
    width: 150,
    key: "type_of_funding_round",
    ...getColumnSearchProps(
      "type_of_funding_round",
      searchText,
      searchedColumn,
      handleSearch,
      handleReset,
      searchInput,
      autoCompleteFilteredOptions,
      setAutoCompleteFilteredOptions
    ),
  },
  // {
  //   title: "Transaction Type",
  //   dataIndex: "type_of_transaction",
  //   width: 150,
  //   key: "type_of_transaction",
  //   ...getColumnSearchProps(
  //     "type_of_transaction",
  //     searchText,
  //     searchedColumn,
  //     handleSearch,
  //     handleReset,
  //     searchInput,
  //     autoCompleteFilteredOptions,
  //     setAutoCompleteFilteredOptions
  //   ),
  // },
  {
    title: "Country",
    width: 150,
    dataIndex: "country_of_the_company",
    key: "country_of_the_company",
    ...getColumnSearchProps(
      "country_of_the_company",
      searchText,
      searchedColumn,
      handleSearch,
      handleReset,
      searchInput,
      autoCompleteFilteredOptions,
      setAutoCompleteFilteredOptions
    ),
  },
  {
    title: "Industry",
    dataIndex: "industry_sector",
    width: 200,
    key: "industry_sector",
    ...getColumnSearchProps(
      "industry_sector",
      searchText,
      searchedColumn,
      handleSearch,
      handleReset,
      searchInput,
      autoCompleteFilteredOptions,
      setAutoCompleteFilteredOptions
    ),
  },
  {
    title: "Investors",
    dataIndex: "investors",
    key: "investors",
    width: 200,
    ...getColumnSearchProps(
      "investors",
      searchText,
      searchedColumn,
      handleSearch,
      handleReset,
      searchInput,
      autoCompleteFilteredOptions,
      setAutoCompleteFilteredOptions
    ),
  },
];

const Dealslist = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [autoCompleteFilteredOptions, setAutoCompleteFilteredOptions] =
    useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Pagination current page
  const [pageSize, setPageSize] = useState(10); // Pagination page size
  const [totalItems, setTotalItems] = useState(0); // Total items for pagination
  const [filters, setFilters] = useState({}); // State to store filters
  const searchInput = useRef(null);

  useEffect(() => {
    fetchData(currentPage, pageSize, filters);
  }, [currentPage, pageSize, filters]);

  const fetchData = async (page, pageSize, filters) => {
    setLoading(true);
    try {
      const response = await api.get("/deal-information/", {
        params: {
          page: page,
          page_size: pageSize,
          ...filters, // Pass filters to the API
        },
      });
      setData(response.data.results);
      setTotalItems(response.data.count);
      setLoading(false);

      // Extract unique values for autocomplete
      const autoCompleteObj = {};
      response.data.results.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (!autoCompleteObj[key]) {
            autoCompleteObj[key] = new Set();
          }
          if (item[key]) {
            autoCompleteObj[key].add(item[key].toString());
          }
        });
      });

      // Convert sets to arrays
      const autoCompleteArrayObj = {};
      Object.keys(autoCompleteObj).forEach((key) => {
        autoCompleteArrayObj[key] = Array.from(autoCompleteObj[key]);
      });

      setAutoCompleteFilteredOptions(autoCompleteArrayObj); // Initialize filtered options
    } catch (error) {
      antdMessage.error("Failed to load data");
      setLoading(false);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    setFilters((prev) => ({ ...prev, [dataIndex]: selectedKeys[0] })); // Update filters
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setFilters({}); // Reset filters
  };

  const handleTableChange = (pagination, filters) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  const exportToCSV = () => {
    const selectedData = selectedRowKeys.map((key) =>
      data.find((item) => item.id === key)
    );
    const worksheet = XLSX.utils.json_to_sheet(selectedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Selected Deals");
    XLSX.writeFile(workbook, "selected_deals.csv");
  };

  const exportToExcel = () => {
    const selectedData = selectedRowKeys.map((key) =>
      data.find((item) => item.id === key)
    );
    const worksheet = XLSX.utils.json_to_sheet(selectedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Selected Deals");
    XLSX.writeFile(workbook, "selected_deals.xlsx");
  };

  return (
    <>
      <Box p={3} boxShadow={2} borderRadius={2}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Space>
            <Button
              onClick={exportToCSV}
              icon={<DownloadOutlined />}
              disabled={selectedRowKeys.length === 0}
            >
              Export to CSV
            </Button>
            <Button
              icon={<FileExcelOutlined />}
              onClick={exportToExcel}
              disabled={selectedRowKeys.length === 0}
            >
              Export to Excel
            </Button>
          </Space>
          <Box >
            <Table
              rowSelection={rowSelection}
              columns={columns(
                searchText,
                searchedColumn,
                handleSearch,
                handleReset,
                searchInput,
                autoCompleteFilteredOptions,
                setAutoCompleteFilteredOptions
              )}
              dataSource={data}
              loading={loading}
              rowKey="id"
              scroll={{ x: "max-content", y: 400 }}
              onChange={handleTableChange}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                showSizeChanger: true,
                pageSizeOptions: ["5", "10", "20", "50", "100"],
                total: totalItems,
              }}
            />
          </Box>
        </Space>
      </Box>
    </>
  );
};

export default Dealslist;
