import React, { useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import { BarLoader } from "react-spinners";
import backgroundImage from "../images/investors2.webp";
import SearchBar from "material-ui-search-bar";

const Search_Investor = gql`
  query Search_Investor($investors_name: String!) {
    investors(where: { investors_name_CONTAINS: $investors_name }) {
      countryInvestedIncountries {
        country
      }
      fundingRoundGivenfundingrounds {
        funding_round_received
      }
      sectorInvestedInmainsectors {
        sector
      }
      investors_name
    }
  }
`;

export default function SearchInvestor() {
  const [investors_name, setInvestorsName] = useState("");

  const [getInvestors, { loading, error, data }] = useLazyQuery(
    Search_Investor,
    {
      variables: { investors_name },
    }
  );

  const columns = [
    { field: "investors_name", headerName: "Investors Name", width: 200 },
    { field: "country", headerName: "Country Invested in", width: 200 },
    { field: "sector", headerName: "Sectors Invested", width: 200 },
    {
      field: "funding_round_received",
      headerName: "Funding Rounds Given",
      width: 200,
    },
  ];

  const rows =
  data?.investors.map((investor, index) => ({
    id: index,
    investors_name: investor.investors_name,
    country: investor.countryInvestedIncountries
      .map((country) => country.country)
      .join(", "),
    sector: investor.sectorInvestedInmainsectors
      .map((sector) => sector.sector)
      .join(", "),
    funding_round_received: investor.fundingRoundGivenfundingrounds
      .map((funding_round) => funding_round.funding_round_received)
      .join(", "),
  })) || [];

  return (
    <>
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          getInvestors();
        }}
        sx={{
          ml: 3,
          padding: 1,
          width: "60%",
          mb: 2,
        }}
      >
        <SearchBar
          value={investors_name}
          onChange={(newValue) => setInvestorsName(newValue)}
          onRequestSearch={() => getInvestors()}
          placeholder="Search for an investor..."
        />
      </Box>
      <Box
        sx={{
          ml: 4,
          padding: 1,
          borderRadius: 3,
          backgroundColor: "background.paper",
          width: "75%",
          height: "80%",
          backgroundImage: data ? "none" : `url(${backgroundImage})`,
          backgroundSize: "cover",
        }}
      >
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <BarLoader color="#9c27b0" loading={loading} />
          </Box>
        )}
        {error && <Alert severity="error">{error.message}</Alert>}
        {data && data.investors.length === 0 && (
          <Alert severity="info">No record found</Alert>
        )}
        {data && data.investors.length > 0 && (
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{ pagination: { paginationModel: { pageSize: 15 } } }}
            disableRowSelectionOnClick
            checkboxSelection
            {...data}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                  printOptions: { disableToolbarButton: true },
                  csvOptions: { disableToolbarButton: true },
                  excelOptions: { disableToolbarButton: true },
          }}}
            pageSizeOptions={[5, 10, 15, 20, 25]}
          />
        )}
      </Box>
    </>
  );
}
