import React from "react";
import { Box, Button, Typography, useTheme, useMediaQuery, Grid } from "@mui/material";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import DealsPieChart from "../../components/DealsPieChart";
import GeographyChart from "../../components/GeographyChart";
import ValueDealsCountry from "../../components/ValueDealsCountry";
import ComparisonDealsYears from "../../components/ComparisonDealsYears";
import DealsList from "../../components/DealsList";

const Deals = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


  const routeChange = () => {
    navigate('/dealsform');
  };

  return (
    <Box m="20px">
      <Box display="flex" flexDirection={isSmallScreen ? "column" : "row"} justifyContent="space-between" alignItems={isSmallScreen ? "flex-start" : "center"} mb={3}>
        <Header title="Deals" subtitle="Breaking down of deals" />
        <Button
        variant="contained"
        color="secondary"
        disabled
          sx={{
            color: colors.white,
            fontSize: "12px",
            fontWeight: "semibold",
            padding: "10px 20px",
            borderRadius: { xs: "5px", md: "10px" },
            background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(127,23,146,1) 35%, rgba(0,212,255,1) 100%)",
            mt: isSmallScreen ? 2 : 0,
          }}
          onClick={routeChange}
        >
          ADD A NEW DEAL
        </Button>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box bgcolor={colors.white} borderRadius={5} p={3}  height="auto">
            <Typography variant="h5" fontWeight="600" color={colors.grey[500]} mb={1}>
              Investment Deals
            </Typography>
            <Typography variant="h6" fontWeight="bold" color={colors.grey[300]} mb={2}>
              Value of deals for top 12 Countries
            </Typography>
            <Box height="320px">
              <ValueDealsCountry isDashboard={true} />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={7}>
          <Box bgcolor={colors.white} borderRadius={5} p={3}  height="auto">
            <Typography variant="h5" fontWeight="600" mb={2}>
              Comparison of Number of Deals between Different Months and Years
            </Typography>
            <Box height={250}>
              <ComparisonDealsYears isDashboard={true} />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={5}>
          <Box bgcolor={colors.white} borderRadius={5} p={3}  height="auto">
            <Typography variant="h5" fontWeight="600" mb={2}>
              Deals per Year
            </Typography>
            <Box height={250}>
              <DealsPieChart isDashboard={true} />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box bgcolor={colors.white} borderRadius={5} p={3}  height="auto">
            <Typography variant="h5" fontWeight="600" mb={2}>
              Deals Distribution
            </Typography>
            <Box height={250}>
              <GeographyChart isDashboard={false} />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box bgcolor={colors.white} borderRadius={5} p={3} height="auto">
            <Typography variant="h5" fontWeight="600" mb={2}>
              Deals List
            </Typography>
            <DealsList isDashboard={true} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Deals;
