import React from "react";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import * as ReactDOM from "react-dom/client";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { KindeProvider } from "@kinde-oss/kinde-auth-react";

const client = new ApolloClient({
  uri: "https://digestafrica-server-tmlms.ondigitalocean.app",
  //uri: "http://localhost:8080",
  cache: new InMemoryCache(),
});


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // Wrap both components inside a single root element
  <React.StrictMode>
    <KindeProvider
    // production
      clientId={process.env.REACT_APP_KINDE_CLIENT_ID}
      domain={process.env.REACT_APP_KINDE_DOMAIN}
      logoutUri={process.env.REACT_APP_KINDE_LOGOUT_URL}
      redirectUri={process.env.REACT_APP_KINDE_REDIRECT_URL}

    // development
      // clientId={process.env.REACT_APP_KINDE_CLIENT_ID_DEV}
      // domain={process.env.REACT_APP_KINDE_DOMAIN_DEV}
      // logoutUri={process.env.REACT_APP_KINDE_LOGOUT_URL_DEV}
      // redirectUri={process.env.REACT_APP_KINDE_REDIRECT_URL_DEV}
    >
      <Provider store={store}>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ApolloProvider>
      </Provider>
    </KindeProvider>
  </React.StrictMode>
);
