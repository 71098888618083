import React from "react";
import { Box, Button, Typography, useTheme, useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import QBarChart from "../../components/QBarChart";
import MapAmount from "../../components/MapAmount";
import RegionalStats from "../../components/RegionalStats";
import TotalDeals from "../../components/TotalDeals";
import TotalInvestment from "../../components/TotalInvestment";
import AverageInvestment from "../../components/AverageInvestment";
import LargestInvestment from "../../components/LargestInvestment";
import RecentDeals from "../../components/RecentDeals";
import SectorCount from "../../components/sectorbarchart";
import DownloadIcon from "@mui/icons-material/Download";
import Blurred from "../../components/Blurred";


const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const getGridSpan = (defaultSpan) => {
    if (isSmallScreen) return 12;
    if (isMediumScreen) return Math.min(defaultSpan * 2, 12);
    return defaultSpan;
  };

  return (
    <Box m="20px">
      <Box display="flex" flexDirection={isSmallScreen ? "column" : "row"} justifyContent="space-between" alignItems={isSmallScreen ? "flex-start" : "center"} mb={3}>
        <Header title="Dashboard" subtitle="Welcome to your dashboard" />
        <Button
          variant="contained"
          color="secondary"
          disabled
          sx={{
            color: colors.white,
            fontSize: "12px",
            fontWeight: "semibold",
            borderRadius: { xs: "5px", md: "10px" },
            background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(127,23,146,1) 35%, rgba(0,212,255,1) 100%)",
            mt: isSmallScreen ? 2 : 0,
          }}
        >
          <DownloadIcon sx={{ mr: "10px" }} />
          Quarterly Reports
        </Button>
      </Box>

      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gap="20px"
      >
        {[TotalDeals, TotalInvestment, AverageInvestment, LargestInvestment].map((Component, index) => (
          <Box
            key={index}
            gridColumn={`span ${getGridSpan(3)}`}
            backgroundColor={colors.white}
            display="flex"
            alignItems="center"
            borderRadius={5}
            p={2}
            height={140}
          >
            <Component />
          </Box>
        ))}

        <Box
          gridColumn={`span ${getGridSpan(8)}`}
          backgroundColor={colors.white}
          borderRadius={5}
          p={3}
          height="auto"
          maxHeight={400}
        >
          <Typography variant="h5" fontWeight="600" color={colors.grey[500]} mb={1}>
            Quarterly value of deals in Millions
          </Typography>
          <Typography variant="h6" fontWeight="bold" color={colors.grey[300]} mb={2}>
            In the past 5 years
          </Typography>
          <Box height={300}>
            <QBarChart isDashboard={true} />
          </Box>
        </Box>

        {/* <Box
            gridColumn={`span ${getGridSpan(4)}`}
            backgroundColor={colors.white}
            overflow="auto"
            borderRadius={3}
            height="auto"
            maxHeight={400}
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600" p={2} borderBottom={`2px solid ${colors.grey[600]}`}>
              Recent Transactions
            </Typography>
            <Box height={340} overflow="auto">
              <RecentDeals isDashboard={true} />
            </Box>
          </Box> */}

          <Box
          gridColumn={`span ${getGridSpan(4)}`}
          backgroundColor={colors.white}
          overflow="auto"
          borderRadius={3}
          height="auto"
          maxHeight={400}
        >
          <Typography color={colors.grey[100]} variant="h5" fontWeight="600" p={2} borderBottom={`2px solid ${colors.grey[600]}`}>
            Recent Transactions
          </Typography>
          <Box height={340} overflow="auto">
            <RecentDeals isDashboard={true} />
          </Box>
        </Box>
      
        <Box
          gridColumn={`span ${getGridSpan(4)}`}
          backgroundColor={colors.white}
          borderRadius={5}
          p={3}
          height="auto"
          width={isSmallScreen ? "100%" : "420px"}
        >
          <Typography variant="h5" fontWeight="600" color={colors.grey[500]} mb={2}>
            Regional Deals Count
          </Typography>
          <Box height={250}>
            <RegionalStats isDashboard={false} />
          </Box>
        </Box>

        <Box
          gridColumn={`span ${getGridSpan(8)}`}
          backgroundColor={colors.white}
          borderRadius={5}
          p={3}
          height="auto"
        >
          <Typography variant="h5" fontWeight="600" color={colors.grey[500]} mb={2}>
            Deals per country
          </Typography>
          <Box height={250}>
            <MapAmount isDashboard={false} />
          </Box>
        </Box>

        <Box
          gridColumn="span 12"
          backgroundColor={colors.white}
          borderRadius={5}
          p={3}
          height="auto"
        >
          <Typography variant="h5" fontWeight="600" color={colors.grey[500]} mb={1}>
            Sector Count
          </Typography>
          <Typography variant="h6" fontWeight="bold" color={colors.grey[300]} mb={2}>
            Top 10 sectors with most companies
          </Typography>
          <Box height={250}>
            <SectorCount />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;