import React from 'react';
import { Box, Button, Typography, useTheme, useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import InvestorDis from "../../components/InvestorDis";
import Investments from "../../components/Investments";
import AmountInvestors from "../../components/AmountInvestors";

const Investor = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const getGridSpan = (defaultSpan) => {
    if (isSmallScreen) return 12;
    if (isMediumScreen) return Math.min(defaultSpan * 2, 12);
    return defaultSpan;
  };

  const routeChange = () => {
    navigate('/investor-form');
  };

  return (
    <Box m="20px">
      <Box display="flex" flexDirection={isSmallScreen ? "column" : "row"} justifyContent="space-between" alignItems={isSmallScreen ? "flex-start" : "center"} mb={3}>
        <Header title="INVESTORS" subtitle="Know your investors" />
        <Button
          variant="contained"
          color="secondary"
          disabled
          sx={{
            color: colors.white,
            fontSize: "12px",
            padding: "10px 20px",
            fontWeight: "semibold",
            borderRadius: { xs: "5px", md: "10px" },
            background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(127,23,146,1) 35%, rgba(0,212,255,1) 100%)",
            mt: isSmallScreen ? 2 : 0,
          }}
          onClick={routeChange}
        >
          ADD AN INVESTOR
        </Button>
      </Box>

      <Box
           display="grid"
           gridTemplateColumns="repeat(12, 1fr)"
           gap="20px"
      >
        <Box
          gridColumn={`span ${getGridSpan(8)}`}
          backgroundColor={colors.white}
          borderRadius={5}
          p={3}
          height="auto"
          maxHeight={400}
        >
          <Typography variant="h5" fontWeight="600" mb={1}>
            Investor Distribution by country
          </Typography>
          <Typography variant="subtitle1" mb={2}>
            Top 12 countries with most investors
          </Typography>
          <Box  height={300}>
            <InvestorDis isDashboard={true} />
          </Box>
        </Box>

        <Box
          gridColumn={`span ${getGridSpan(4)}`}
          backgroundColor={colors.white}
          overflow="auto"
          borderRadius={3}
          height="auto"
          maxHeight={400}
        >
          <Typography variant="h6" p={2} borderBottom={`2px solid ${colors.primary[300]}`}>
            Investors who have invested most in companies
          </Typography>
          <Box height={340} overflow="auto" >
            {mockTransactions.map((transaction, i) => (
              <Box
                key={`${transaction.txId}-${i}`}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={`1px solid ${colors.primary[300]}`}
                p={2}
              >
                <Box>
                  <Typography variant="subtitle1" fontWeight="600">
                    {transaction.txId}
                  </Typography>
                  <Typography variant="body2">{transaction.user}</Typography>
                </Box>
                <Typography variant="body2">{transaction.date}</Typography>
                <Box
                  backgroundColor={colors.parent_palatte[100]}
                  p="5px 10px"
                  borderRadius="4px"
                >
                  ${transaction.cost}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        <Box
           gridColumn={`span ${getGridSpan(4)}`}
           backgroundColor={colors.white}
           borderRadius={5}
           p={3}
           height="auto"
        >
          <Typography variant="h5" fontWeight="600" mb={2}>
            Big money investors
          </Typography>
          <Box height={250}>
            <AmountInvestors isDashboard={true} />
          </Box>
        </Box>

        <Box
          gridColumn={`span ${getGridSpan(8)}`}
          backgroundColor={colors.white}
          borderRadius={5}
          p={3}
        >
          <Typography variant="h5" fontWeight="600" mb={1}>
            Investments Count
          </Typography>
          <Typography variant="subtitle1" mb={2}>
            Total Investments made by top investors
          </Typography>
          <Box height={250}>
            <Investments isDashboard={false} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Investor;
