import React, { useMemo } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import WavingHandRoundedIcon from '@mui/icons-material/WavingHandRounded';
import InsightsIcon from '@mui/icons-material/Insights';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { keyframes } from '@mui/system';
import image from '../images/da_signup.jpg';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#ff4081',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h4: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 500,
    },
  },
});

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const LoggedOut = React.memo(({ onGuestAccess }) => { // Accept the guest access handler
  const { login, register } = useKindeAuth();

  const styles = useMemo(
    () => ({
      mainGrid: {
        height: '100vh',
        backgroundColor: '#f4f6f8',
      },
      imageGrid: {
        backgroundImage: `url(${image})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        animation: `${fadeIn} 2s ease-in-out`,
      },
      box: {
        my: 8,
        mx: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        animation: `${slideIn} 1s ease-in-out`,
      },
      button: {
        mt: 2,
        mb: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'transform 0.3s ease',
        '&:hover': {
          transform: 'scale(1.05)',
        },
      },
    }),
    []
  );

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={styles.mainGrid}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={styles.imageGrid}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box sx={styles.box}>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <WavingHandRoundedIcon />
            </Avatar>
            <Typography component="h1" variant="h4" sx={{ fontWeight: 'bold' }}>
              Welcome to Digest Africa
            </Typography>
            <Typography component="h2" variant="h6" sx={{ mt: 2, textAlign: 'center' }}>
              Your one-stop platform for analytics, insights, and visualizations.
            </Typography>
            <Typography component="p" sx={{ mt: 2, textAlign: 'center', color: 'text.secondary' }}>
              Gain valuable insights into the African tech ecosystem with our comprehensive analytics and visualizations. Sign up or log in to access personalized dashboards and more!
            </Typography>
            <Box sx={{ mt: 4, width: '100%' }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                sx={styles.button}
                startIcon={<InsightsIcon />}
                onClick={login}
              >
                Sign In
              </Button>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                sx={styles.button}
                startIcon={<PersonAddIcon />}
                onClick={register}
              >
                Sign Up
              </Button>
              <Button
                fullWidth
                variant="text"
                color="primary"
                sx={styles.button}
                onClick={onGuestAccess} // Add guest access button
              >
                Continue as Guest
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
});

export default LoggedOut;
